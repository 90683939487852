/**
 * @module ManageRoutes
 * @author David Kirkland <david.kirkland@nec.com.au>
 */
import React from 'react';
import { Flex } from '@aws-amplify/ui-react';
import Container from '@mui/material/Container';
import Button from '@mui/material/Button';
import CategoryIcon from '@mui/icons-material/CategoryOutlined';
import {
    getAwsRouteList, copyAwsRoute, moveAwsRoute, removeAwsRoute,
    storageFolderDraft, storageFolderReview, storageFolderApproved, storageFolderArchive, storageFolderReference
} from './AwsFunctions';
import { AwsRouteList, ManageAwsRouteDialog } from './RouteDialogs';
import { AlertDialog } from './DialogUtils';
import { AlertSeverity } from './AppDefs';
import PropTypes from 'prop-types';

// route file actions
const routeActionApprove = 'approve',
    routeActionReview = 'review',
    routeActionArchive = 'archive',
    routeActionRestore = 'restore',
    routeActionDeleteDraft = 'delete_draft',
    routeActionDeleteReview = 'delete_review';

/**
 * Class to manage routes in AWS
 */
export default class ManageRoutes extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            routeList: null,
            selectedRouteFolder: "",
            selectedItemKey: null,
            selectedRoute: null,
            alertDialogContent: null,
            alertDialogTitle: null
        }

        // alert dialog callbacks
        this.alertDialogCallbackOK = null;
        this.alertDialogCallbackCancel = null;
    }

    componentDidMount() {
        if (!this.alreadyMounted) {
            this.alreadyMounted = true;
            // retrieve the list of routes from AWS S3
            this.updateRouteList(true);
        }
    }

    componentDidUpdate(prevProps, prevState) {
        if (this.props.activeCustomer !== prevProps.activeCustomer) {
            this.updateRouteList(true);
        }
    }

    /**
     * Retrieve the latest route list from AWS
     */
    updateRouteList(alertOnError = false) {
        const showCircularProgressBar = this.props.showCircularProgressBar;

        showCircularProgressBar(true);
        getAwsRouteList((routeList) => {
            showCircularProgressBar(false);
            this.setState({ routeList: routeList ? this.groupRouteList(routeList) : null })
            if (routeList === null && alertOnError) {
                this.props.onAlert("Unable to load route list", AlertSeverity.ERROR, 5000, true);
            }
        });
    }

    /**
     * Group the route list by filename
     * @param {Object} routeList 
     * @returns {Object} grouped route list
     */
    groupRouteList(routeList) {
        let groupedList = [];
        for (let r of routeList) {
            if (r.folder === storageFolderReference) {
                // skip reference copies of routes
                continue;
            }
            let i;
            for (i = 0; i < groupedList.length; i++) {
                if (r.fileName === groupedList[i].key) {
                    break;
                }
            }
            if (i >= groupedList.length) {
                let n = {
                    route: r.route,
                    destination: r.destination,
                    variant: r.variant,
                    key: r.fileName,
                    folders: [],
                    versions: []
                };
                groupedList.push(n);
            }
            if (!groupedList[i].folders.includes(r.folder)) {
                groupedList[i].folders.push(r.folder);
                groupedList[i].versions.push({ folder: r.folder, lastModified: r.lastModified });
            }
        }

        //console.log("Grouped routes: " + routeList.length + " -> " + groupedList.length);
        //console.log(groupedList);
        return groupedList;
    }

    /**
   * Show the alert dialog
   * @param {boolean} show true to show the dialog, false to hide it
   * @param {string} title the title to display in the dialog 
   * @param {string} content the text to display in the dialog
   * @param {function} onOk callback function for when OK is clicked
   * @param {function} onCancel callback function for when cancel is clicked
   */
    showAlertDialog(show, title, content, onOk, onCancel) {
        if (show) {
            this.alertDialogCallbackOK = onOk;
            this.alertDialogCallbackCancel = onCancel;
            this.setState({ alertDialogContent: content, alertDialogTitle: title });
        } else {
            this.setState({ alertDialogContent: null, alertDialogTitle: null });
        }
    }

    /**
     * Select the route to be managed
     * @param {string} key the route key (filename)
     */
    selectRouteToManage(key) {
        const { routeList } = this.state;
        this.setState({ selectedRoute: routeList.filter(val => val.key === key)[0] })
    }

    /**
     * Move a version of the selected route to a different folder
     * @param {string} srcFolder the source folder
     * @param {string} destFolder the destination folder
     * @param {string} key the route key (filename)
     * @param {string} description a text description to be presented to the user
     */
    moveRoute(srcFolder, destFolder, key, description) {
        const onAlert = this.props.onAlert,
            showCircularProgressBar = this.props.showCircularProgressBar;

        showCircularProgressBar(true);
        moveAwsRoute(srcFolder + key, destFolder + key,
            (error) => {
                showCircularProgressBar(false);
                if (error) {
                    onAlert("Unable to mark route as " + description, AlertSeverity.ERROR, 0);
                } else {
                    onAlert("Route marked as " + description, AlertSeverity.SUCCESS, 5000, true);
                    this.updateRouteList();
                }
            });
    }

    /**
     * Delete a version of the selected route to a different folder
     * @param {string} folder the source folder
     * @param {string} route the route details
     * @param {string} description a text description to be presented to the user
     */
    deleteRoute(folder, route, description) {
        const onAlert = this.props.onAlert,
            showCircularProgressBar = this.props.showCircularProgressBar;

        if (route.folders.includes(folder)) {
            this.showAlertDialog(true, 'Confirm Deletion', 'Are you sure you want to delete the ' + description + ' version of route ' +
                route.route + ' - ' + route.destination + '?',
                () => {
                    this.showAlertDialog(false);
                    showCircularProgressBar(true);
                    removeAwsRoute(folder + route.key,
                        (error) => {
                            showCircularProgressBar(false);
                            if (error) {
                                onAlert("Unable to delete route (" + description + ")", AlertSeverity.ERROR, 0);
                            } else {
                                onAlert("Route (" + description + ") deleted", AlertSeverity.SUCCESS, 5000, true);
                                this.updateRouteList();
                            }
                        });
                },
                () => {
                    // user cancelled the request
                    this.showAlertDialog(false);
                });
        }
    }

    /**
     * Handle the specified action for the selected route
     * @param {string} action the action to take
     */
    handleRouteAction(action) {
        const onAlert = this.props.onAlert,
            showCircularProgressBar = this.props.showCircularProgressBar,
            { selectedRoute } = this.state;

        switch (action) {
            case routeActionApprove:
                // Move route from "review" to "approved".  If any "approved" version already exists, move it to "archive" first.
                if (selectedRoute.folders.includes(storageFolderReview)) {
                    if (selectedRoute.folders.includes(storageFolderApproved)) {
                        this.showAlertDialog(true, 'Warning', 'An approved version of this route already exists - overwrite?',
                            () => {
                                this.showAlertDialog(false);
                                // copy the existing approved route file to the archive folder
                                showCircularProgressBar(true);
                                copyAwsRoute(storageFolderApproved + selectedRoute.key, storageFolderArchive + selectedRoute.key,
                                    (error) => {
                                        showCircularProgressBar(false);
                                        if (error) {
                                            onAlert("Route approval failed", AlertSeverity.ERROR, 0);
                                        } else {
                                            this.moveRoute(storageFolderReview, storageFolderApproved, selectedRoute.key, "approved");
                                        }
                                    });

                            },
                            () => {
                                // user cancelled the request
                                this.showAlertDialog(false);
                            });
                    } else {
                        this.moveRoute(storageFolderReview, storageFolderApproved, selectedRoute.key, "approved");
                    }
                } else {
                    onAlert("Route is not available for approval", AlertSeverity.WARNING, 5000, true);
                }
                break;
            case routeActionReview:
                // Move route from "draft" to "review".  If any "review" version already exists, it is overwritten.
                if (selectedRoute.folders.includes(storageFolderDraft)) {
                    if (selectedRoute.folders.includes(storageFolderReview)) {
                        this.showAlertDialog(true, 'Warning', 'This route is already under review - overwrite?',
                            () => {
                                this.showAlertDialog(false);
                                this.moveRoute(storageFolderDraft, storageFolderReview, selectedRoute.key, "for review");
                            },
                            () => {
                                // user cancelled the request
                                this.showAlertDialog(false);
                            });
                    } else {
                        this.moveRoute(storageFolderDraft, storageFolderReview, selectedRoute.key, "for review");
                    }
                } else {
                    onAlert("Draft version of route does not exist", AlertSeverity.WARNING, 5000, true);
                }
                break;
            case routeActionArchive:
                // Move route from "approved" to "archive".  If any "archive" version already exists, it is overwritten.
                if (selectedRoute.folders.includes(storageFolderApproved)) {
                    this.moveRoute(storageFolderApproved, storageFolderArchive, selectedRoute.key, "archived");
                }
                break;
            case routeActionDeleteDraft:
                // Delete the draft version of a route.
                this.deleteRoute(storageFolderDraft, selectedRoute, "draft");
                break;
            case routeActionDeleteReview:
                // Delete the review version of a route.
                this.deleteRoute(storageFolderReview, selectedRoute, "review");
                break;
            case routeActionRestore:
                // Move route from "archived" back to "approved".  If any "archive" version already exists, it will be overwritten.
                if (selectedRoute.folders.includes(storageFolderArchive)) {
                    if (selectedRoute.folders.includes(storageFolderApproved)) {
                        this.showAlertDialog(true, 'Warning', 'An approved version of this route already exists - overwrite?',
                            () => {
                                this.showAlertDialog(false);
                                this.moveRoute(storageFolderArchive, storageFolderApproved, selectedRoute.key, "approved");
                            },
                            () => {
                                // user cancelled the request
                                this.showAlertDialog(false);
                            });
                    } else {
                        this.moveRoute(storageFolderArchive, storageFolderApproved, selectedRoute.key, "approved");
                    }
                }
                break;
            default:
                break;
        }
    }

    render() {
        const {
            routeList,
            selectedRouteFolder,
            selectedItemKey,
            selectedRoute,
            alertDialogContent,
            alertDialogTitle
        } = this.state;

        return (
            <Container maxWidth={false} disableGutters sx={{ marginTop: 1 }}>
                <AwsRouteList
                    listItems={routeList && routeList.filter(val => (!selectedRouteFolder || val.folders.includes(selectedRouteFolder)))}
                    defaultFolder={selectedRouteFolder}
                    onSelectedFolderChange={(folder) => { this.setState({ selectedRouteFolder: folder }) }}
                    onClick={(key) => { this.setState({ selectedItemKey: key }) }}
                    onDoubleClick={(key) => { this.selectRouteToManage(key) }}
                    showFolders
                    showAllOption
                    showArchiveOption
                    onRefreshList={() => this.updateRouteList()}
                    // slightly dodgy calculation to make the list of items mostly fill the page
                    itemsPerPage={window.innerHeight < 500 ? 5 : (window.innerHeight - 290 - this.props.excludeHeight) / 50}
                />

                <Flex justifyContent="center" style={{ padding: '1rem' }}>
                    <Button
                        variant="contained"
                        style={{ minWidth: '8rem' }}
                        disabled={selectedItemKey === null}
                        startIcon={<CategoryIcon />}
                        onClick={() => { this.selectRouteToManage(selectedItemKey) }}>
                        Manage Route
                    </Button>
                </Flex>

                <ManageAwsRouteDialog
                    content={selectedRoute}
                    onOk={(action) => {
                        this.handleRouteAction(action);
                        this.setState({ selectedRoute: null });
                    }}
                    onCancel={() => { this.setState({ selectedRoute: null }); }}
                />

                <AlertDialog
                    title={alertDialogTitle}
                    content={alertDialogContent}
                    onOk={this.alertDialogCallbackOK}
                    onCancel={this.alertDialogCallbackCancel}
                />
            </Container>
        );
    }
}

ManageRoutes.propTypes = {
    activeCustomer: PropTypes.object,
    excludeHeight: PropTypes.number,
    onAlert: PropTypes.func.isRequired,
    showCircularProgressBar: PropTypes.func.isRequired
};

export {
    routeActionApprove, routeActionArchive, routeActionRestore, routeActionReview, routeActionDeleteDraft, routeActionDeleteReview
};