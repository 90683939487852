/**
 * Functions for utilising AWS services (using AWS Amplify)
 * @author David Kirkland <david.kirkland@nec.com.au>
 */
import { API, graphqlOperation, Storage, Auth } from 'aws-amplify';
import { Logger } from 'aws-amplify';
import { getAppConfiguration, getCustomerList, accessTmsApi } from './graphql/queries';
import { parseGeoJSONString, getRouteDetailsFromGeoJSONFilename } from './GeoJsonUtils';
import { routeSorter } from './RouteUtils';

// S3 bucket folders
const storageFolderDraft = 'working/',
    storageFolderReview = 'review/',
    storageFolderApproved = 'release/',
    storageFolderArchive = 'archive/',
    storageFolderReference = 'reference/';

const logger = new Logger('AwsFunctions', 'INFO');

/**
 * Fetch the app configuration values from the AWS Parameter Store
 * @async
 * @param {*} callBack the function to call after retrieving the configuration values
 */
async function fetchAppConfiguration(callBack) {
    if (!callBack) {
        return;
    }
    try {
        const result = await API.graphql(graphqlOperation(getAppConfiguration));
        try {
            let configList = JSON.parse(result.data.getAppConfiguration);
            callBack(configList);
        } catch (e) {
            console.error("Error parsing the app configuration response", e);
            callBack();
        }
    } catch (error) {
        console.error("error fetching the app configuration", error);
        callBack();
    }
}

/**
 * Fetch the customer list from DynamoDB (using a Lambda function)
 * @async
 * @param {*} callBack the function to call after retrieving the customer list
 */
async function fetchCustomerList(callBack) {
    if (!callBack) {
        return;
    }
    try {
        const result = await API.graphql(graphqlOperation(getCustomerList));
        try {
            let customerList = JSON.parse(result.data.getCustomerList);
            // sort the customer list by display name
            customerList.sort((a, b) => {
                if (!a.displayName || !b.displayName)
                    return 0;
                else
                    return a.displayName.localeCompare(b.displayName);
            });
            callBack(customerList);
        } catch (e) {
            console.error("Error parsing the customer list");
            callBack();
        }
    } catch (error) {
        console.error("error fetching the customer list", error);
        callBack();
    }
}

/**
 * Fetch the details of the currently logged-in user
 * @param {*} callBack the function to call after retrieving the user's details
 */
function checkAuthenticatedUser(callBack) {
    Auth.currentAuthenticatedUser({
        bypassCache: true // Optional, By default is false. If set to true, this call will send a request to Cognito to get the latest user data
    })
        .then((user) => {
            //console.log(user);
            const groups = user.signInUserSession.accessToken.payload["cognito:groups"];
            if (callBack) {
                callBack({
                    'email': user.attributes.email,
                    'name': user.attributes.name,
                    'isVerified': user.attributes.email_verified,
                    'groups': groups,
                    'customer': user.attributes['custom:customer']
                });
            }
        })
        .catch((err) => {
            logger.error(err)
            callBack(null);
        });


}

/**
 * Change the password for the currently logged-in user
 * @param {string} oldPassword the current password
 * @param {string} newPassword the new password
 * @param {*} callBack the function to call after changing the password
 */
function changeCurrentUserPassword(oldPassword, newPassword, callBack) {
    Auth.currentAuthenticatedUser({
        bypassCache: true // Optional, By default is false. If set to true, this call will send a request to Cognito to get the latest user data
    })
        .then((user) => {
            return Auth.changePassword(user, oldPassword, newPassword);
        })
        .then((data) => callBack(null))
        .catch((err) => callBack(err.message));
}

/**
 * Configure AWS S3 storage
 * @param {string} customer the name of the customer associated with the logged-in user
 * @param {string} customerBucket the name of the storage bucket to user for the logged-in user (optional)
 */
function configureAwsStorage(customer, customerBucket) {
    let storageConfiguration = {
        level: 'public',
        customPrefix: {
            public: 'routes/'
        }
    }

    if (customer || customerBucket) {
        storageConfiguration.bucket = customerBucket ? customerBucket.trim() : 'routemanager-routes-' + customer;
        logger.info("Configuring storage bucket: " + storageConfiguration.bucket);
    } else {
        logger.info("Configuring default storage");
    }
    Storage.configure(storageConfiguration);
}

/**
 * List AWS S3 storage contents
 */
function listAwsStorage() {
    Storage.list('')
        .then(({ results }) => logger.debug("!!!", results))
        .catch((err) => logger.error("Error listing AWS storage contents: ", err));
}

/**
 * Retrieve a list of routes from AWS S3 storage
 * @param {*} callBack the function to call after reading the route list
 */
function getAwsRouteList(callBack) {
    Storage.list('', { pageSize: 'ALL' })
        .then(({ results }) => {
            let routeList = [];
            results.filter(val => val.key.endsWith('.geojson')).forEach((val) => {
                let route = {};
                let key = val.key;
                // store the full route key (including the folder prefix)
                route.key = key;

                // remove the folder prefix (if it exists)
                let p = key.lastIndexOf('/');
                if (p >= 0) {
                    route.folder = key.slice(0, p + 1);
                    key = key.slice(p + 1);
                }
                // store the filename so it can be used to group all versions of the route later
                route.fileName = key;
                // split the key into its separate components
                let details = getRouteDetailsFromGeoJSONFilename(key);
                if (details !== null) {
                    route.route = details.route;
                    route.destination = details.destination;
                    if (details.variant !== null) {
                        route.variant = details.variant;
                    }
                    route.lastModified = val.lastModified;
                    routeList.push(route);
                }
            });
            // sort the route list
            routeList.sort(routeSorter);
            callBack(routeList);
        })
        .catch((err) => {
            logger.error("Error getting AWS route list:", err.message)
            callBack(null);
        });
}

/**
 * Check if a route file exists in AWS
 * @param {string} fileName the name of the file
 * @param {Object} routeList AWS route list to check
 * @returns {boolean} true if the file already exists
 */
function routeExistsInAws(fileName, routeList) {
    return routeList.filter(val => val.key === fileNameWithFolder(fileName)).length > 0;
}

/**
 * Check if a route file exists in an AWS folder
 * @param {string} fileName the name of the file
 * @param {string} folder the name of the folder to check
 * @param {Object} routeList AWS route list to check
 * @returns {boolean} true if the file already exists
 */
function routeExistsInAwsFolder(fileName, folder, routeList) {
    return routeList.filter(val => (val.fileName === fileName && val.folder === folder)).length > 0;
}

/**
 * Upload a route file to AWS S3 storage
 * @async
 * @param {{route:string, destination:string, id:string}} routeDetails the details of the route contained in the file
 * @param {string} fileName the name of the file
 * @param {any} fileContents the contents of the file
 * @param {number} versionNumber the version of the route file
 * @param {*} callBack the function to call after saving the route
*/
async function saveRouteToAws(routeDetails, fileName, fileContents, versionNumber, callBack) {
    // fileName should include a folder
    fileName = fileNameWithFolder(fileName);

    try {
        const result = await Storage.put(fileName, fileContents, {
            cacheControl: 'no-cache',
            contentType: "application/json",
            metadata: {
                route: String(routeDetails.route),
                destination: String(routeDetails.destination),
                variant: String(routeDetails.variant),
                version: (versionNumber ? String(versionNumber) : "1")
            },
            // progressCallback(progress) {
            //     console.log(`Uploaded: ${progress.loaded}/${progress.total}`);
            // },
        });
        logger.debug(result);
        if (result && result.key === fileName) {
            logger.info("Route saved to AWS: " + fileName, routeDetails.route, routeDetails.destination, routeDetails.variant);
            callBack(null);
        } else {
            callBack("unknown error");
        }
    } catch (error) {
        logger.error("Error saving " + fileName + " to AWS -", error);
        callBack(String(error));
    }
}

/**
 * Retrieve a route file from AWS S3 storage
 * @async
 * @param {string} fileName the name of the route file to retrieve
 * @param {*} callBack the function to call after retrieving the route
 */
async function retrieveRouteFromAws(fileName, callBack) {
    try {
        const result = await Storage.get(fileName, {
            download: true,
            cacheControl: 'no-cache'
            // progressCallback(progress) {
            //     console.log(`Downloaded: ${progress.loaded}/${progress.total}`);
            // }
        });
        // result.Body is a Blob
        result.Body.text().then((string) => {
            logger.info("Retrieved " + fileName + " from AWS, length = " + string.length);

            let geoJson = parseGeoJSONString(fileName, string);
            callBack(fileName, geoJson.error, geoJson.obj, true);
        });
    } catch (error) {
        logger.error("Error retrieving " + fileName + " from AWS -", error);
        callBack(fileName, "Error: unable to load " + fileName, null, true);
    }
}

/**
 * Copy a route file to a new location in the AWS S3 bucket
 * @async
 * @param {string} srcKey the name of the file to copy
 * @param {string} destKey the name of the destination file
 * @param {*} callBack the function to call after copying the route
 */
async function copyAwsRoute(srcKey, destKey, callBack) {
    try {
        const result = await Storage.copy({ key: srcKey }, { key: destKey });
        logger.debug(result);
        if (result && result.key === destKey) {
            logger.info("Route copied from " + srcKey + " to " + destKey);
            callBack(null);
        } else {
            callBack("unknown error");
        }
    } catch (error) {
        logger.error("Error copying " + srcKey + " to " + destKey, error);
        callBack(String(error));
    }
}


/**
 * Move a route file to a new location in the AWS S3 bucket
 * @async
 * @param {string} srcKey the name of the file to move
 * @param {string} destKey the name of the destination file
 * @param {*} callBack the function to call after moving the route
 */
async function moveAwsRoute(srcKey, destKey, callBack) {
    try {
        const result = await Storage.copy({ key: srcKey }, { key: destKey });
        logger.debug(result);
        if (result && result.key === destKey) {
            const result = await Storage.remove(srcKey);
            logger.debug(result);
            callBack(null);
        } else {
            callBack("unknown error");
        }
    } catch (error) {
        logger.error("Error moving " + srcKey + " to " + destKey, error);
        callBack(String(error));
    }
}


/**
 * Remove a route file from AWS S3 storage
 * @async
 * @param {string} fileName the name of the route file to remove
 * @param {*} callBack the function to call after removing the route
 */
async function removeAwsRoute(fileName, callBack) {
    try {
        const result = await Storage.remove(fileName);
        logger.debug(result);
        callBack(null);
    } catch (error) {
        logger.error("Error removing " + fileName + " from AWS", error);
        callBack(String(error));
    }
}


/**
 * Check if a route is read-only
 * @param {string} fileName the name of the route file
 * @returns {boolean} true if route is read-only
  */
function routeIsReadOnly(fileName) {
    return !fileNameWithFolder(fileName).startsWith(storageFolderDraft);
}

/**
 * Get the name of the folder that contains a route file
 * @param {string} fileName the name of the route file
 * @returns {string} the folder name
  */
function getRouteSourceFolder(fileName) {
    return fileNameWithFolder(fileName).split('/')[0];
}

/**
 * Check if the fileName includes a folder and add the default folder if not
 * @param {string} fileName the name of the route file
 * @returns {string} the fileName including the folder
  */
function fileNameWithFolder(fileName) {
    // fileName should include a folder
    if (!fileName.includes('/')) {
        fileName = storageFolderDraft.concat(fileName);
    }
    return fileName;
}

/**
 * Access a TMS API via the AWS proxy function (avoids CORS issues)
 * @async
 * @param {*} request the request to send to the TMS API
 * @param {*} callBack the function to call after retrieving the API key
 */
async function accessTmsApiViaAws(request, callBack) {
    if (!request || !callBack) {
        return;
    }
    try {
        logger.debug("Sending request to TMS API: " + request);
        const result = await API.graphql(graphqlOperation(accessTmsApi, { request: request }));
        let result2 = JSON.parse(result.data.accessTmsApi);
        logger.debug('Got response from TMS API: ' + result2.statusCode);
        callBack((result2.statusCode === 200), result2.body);
    } catch (error) {
        let errorMessage = "Unknown error";
        if (typeof error === 'object' && error !== null && error.hasOwnProperty('errors') && error.errors.length > 0) {
            errorMessage = error.errors[0].message;
        }
        logger.error("Error accessing TMS API: " + errorMessage);
        callBack(false, errorMessage);
    }
}

export {
    fetchAppConfiguration, fetchCustomerList, checkAuthenticatedUser, configureAwsStorage, listAwsStorage,
    saveRouteToAws, retrieveRouteFromAws, getAwsRouteList, accessTmsApiViaAws, changeCurrentUserPassword,
    copyAwsRoute, moveAwsRoute, removeAwsRoute, routeIsReadOnly, routeExistsInAws, routeExistsInAwsFolder, getRouteSourceFolder,
    storageFolderDraft, storageFolderReview, storageFolderApproved, storageFolderArchive, storageFolderReference
};
