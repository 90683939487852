/**
 * Map Utility functions
 * @author David Kirkland <david.kirkland@nec.com.au>
 */
import H from "@here/maps-api-for-javascript";
import bus_stop_icon_blue from './images/ic_bus_stop_icon.png'
import bus_stop_icon_green from './images/ic_bus_stop_icon_green.png'
import bus_stop_icon_red from './images/ic_bus_stop_icon_red.png'
import location_icon_grey from './images/ic_location_icon_darkgrey.png'
import green_dot from './images/green_dot.png'
import red_dot from './images/red_dot.png'
import { Logger } from 'aws-amplify';

const logger = new Logger('MapUtils', 'INFO');

const stopMarkerIconSize = 40;  // pixels
const routeEndPointsIconSize = 20;  // pixels

const mapGroupNamePlannedRoute = "planned route",
    mapGroupNameCalculatedRoute = "calculated route",
    mapGroupNameReferenceRoute = "reference route";

const minRouteWaypointInterval = 150,       // minimum distance between points on the route geometry when creating a filtered route (metres)
    maxStopDistanceFromRoutePoint = 50,     // maximum distance a stop can be from a point on the route before we warn the user (metres)
    maxStopDistanceFromRouteSegment = 30,   // maximum distance a stop can be from a route segment before we warn the user (metres)
    minStopInterval = 20,                   // minimum distance between sequential stops on the route (metres)
    maxRouteWaypoints = 120;                // maximum number of waypoints permitted 
//   (approximate value - the HERE routing API imposes a limit on the length of the query that is sent to the REST API)

/**
 * Create a polyline (with associated set of vertice points) for a route
 * 
 * @param {{lat:number, lng:number}[]} geometry the route geometry
 * @returns {{polyline:H.map.Polyline, verticeGroup:H.map.Group}} the route polyline and a group of map markers representing the points on the route
 */
function createRoutePolyline(geometry) {
    let lineString = new H.geo.LineString();
    for (const point of geometry) {
        lineString.pushPoint(point);
    }

    let polyline = new H.map.Polyline(lineString, { style: { strokeColor: 'blue', lineWidth: 4 } });
    //let polylineTimeout;
    let verticeGroup = new H.map.Group({ visibility: false });

    // ensure that the polyline can receive drag events
    polyline.draggable = true;

    // create markers for each polyline's vertice which will be used for dragging
    let svgCircle = '<svg width="20" height="20" version="1.1" xmlns="http://www.w3.org/2000/svg">' +
        '<circle cx="10" cy="10" r="6" fill="transparent" stroke="brown" stroke-width="2"/>' +
        '</svg>';
    let icon = new H.map.Icon(svgCircle, { anchor: { x: 10, y: 10 } })
    polyline.getGeometry().eachLatLngAlt(function (lat, lng, alt, index) {
        let vertice = new H.map.Marker(
            { lat, lng },
            { icon: icon });
        vertice.draggable = true;
        vertice.setData({ 'verticeIndex': index })
        verticeGroup.addObject(vertice);
    });

    // return the polyline and its vertices
    return { polyline, verticeGroup };
}

/**
 * Create a reference polyline for a route
 * 
 * @param {{lat:number, lng:number}[]} geometry the route geometry
 * @returns {polyline:H.map.Polyline} the route polyline
 */
function createReferenceRoutePolyline(geometry) {
    let lineString = new H.geo.LineString();
    for (const point of geometry) {
        lineString.pushPoint(point);
    }

    let polyline = new H.map.Polyline(lineString, {
        style: {
            strokeColor: 'DarkCyan',
            lineDash: [0, 2],
            lineWidth: 12,
            lineCap: 'round'
        },
        data: mapGroupNameReferenceRoute
    });
    return polyline;
}

/**
 * Create a group of map markers to indicate the stops on a route
 * 
 * @param {{lat:number, lng:number}[]} stops the stop locations
 * @returns {H.map.Group} the group of stop objects
 */
function createStopMarkers(stops) {
    let stopsGroup = new H.map.Group();
    let lastSeqNo = 0;

    // determine the final stop (should be the last stop in the list)
    for (const stop of stops) {
        if (stop.sequenceNo && stop.sequenceNo > lastSeqNo)
            lastSeqNo = stop.sequenceNo;
    }

    // create a map marker for each stop and add it to the group
    for (const stop of stops) {
        let marker = createStopMarker({ lat: stop.lat, lng: stop.lng }, stop, lastSeqNo, false);
        stopsGroup.addObject(marker);
    }

    return stopsGroup;
}

/**
 * Create a map marker to indicate a stop/waypoint on a route
 * 
 * @param {{lat:number, lng:number}} location the stop location
 * @param {{}} stop the stop properties
 * @param {number} lastStopSequenceNumber the sequence number of the last stop on the route
 * @param {boolean} draggable true if the marker should be draggable when created
 * @returns {H.map.Marker} the map marker object for the stop/waypoint
 */
function createStopMarker(location, stop, lastStopSequenceNumber, draggable) {
    // create a map marker for the stop
    let marker = new H.map.Marker(location);
    marker.setData(stop ? stop : {});
    setBusStopIcon(marker, lastStopSequenceNumber);
    marker.setVolatility(true);
    marker.draggable = draggable;

    return marker;
}

/**
 * Set the icon for a stop/waypoint map marker
 * 
 * @param {H.map.Marker} the map marker object for the stop/waypoint
 * @param {number} lastStopSequenceNumber the sequence number of the last stop on the route
 */
function setBusStopIcon(stopMarker, lastStopSequenceNumber) {
    let stop = stopMarker.getData();
    let iconSize = stopMarkerIconSize;
    let iconType = !isBusStop(stop) ? location_icon_grey : stop.sequenceNo === 1 ? bus_stop_icon_green :
        stop.sequenceNo === lastStopSequenceNumber ? bus_stop_icon_red : bus_stop_icon_blue;

    stopMarker.setIcon(new H.map.Icon(iconType, { size: { h: iconSize, w: iconSize } }));
}

/**
 * Check if a stop is an actual bus stop or a waypoint
 * @param {{}} stopData the stop properties
 * @returns {boolean} true if this is a bus stop, or false if it is a pass-through waypoint
 */
function isBusStop(stopData) {
    return stopData && stopData.hasOwnProperty('stopName');
}

/**
 * Update the sequence numbers and icons for the stops on the route
 * 
 * @param {H.map.Group} stops the group of stop Map Marker objects
 * @returns {number} the number of stops where the sequence number was updated
 */
function updateStopSequenceNumbers(stops) {
    let seqNo = 0,
        updateCount = 0;
    // check that the stop sequence numbers are correct
    for (const stop of stops) {
        let stopData = stop.getData();
        if (isBusStop(stopData)) {
            seqNo++;
            if (stopData.sequenceNo !== seqNo) {
                stopData.sequenceNo = seqNo;
                updateCount++;
            }
        }
    }

    // update the icon colours
    for (const stop of stops) {
        setBusStopIcon(stop, seqNo);
    }

    return updateCount;
}

/**
 * Add a point to the route
 * 
 * @param {H.map.Polyline} polyline the route polyline
 * @param {H.map.Group} vertices the points on the route
 * @param {number} addIndex where to add the new point
 * @param {boolean} insertBefore true to add the point before the addIndex, otherwise after
 * @param {H.geoPoint | null} point the the location of the point to insert
 * @param {number | null} insertRatio where to insert a new point (if point is null)
 * @returns {number | null} where the point was added
 */
function insertRoutePointAtIndex(polyline, vertices, addIndex, insertBefore, point, insertRatio) {
    let insertionIndex = 0,
        nextIndex = addIndex;
    if (addIndex < 0 || addIndex > (polyline.getGeometry().getPointCount() - 1)) {
        return null;
    } else if (insertBefore) {
        if (addIndex > 0) {
            // add a new point on the route mid-way between the selected point and the previous point
            nextIndex = addIndex - 1;
        }
        insertionIndex = addIndex;
    } else {
        if (addIndex < (polyline.getGeometry().getPointCount() - 1)) {
            // add a new point on the route mid-way between the selected point and the next point
            nextIndex = addIndex + 1;
        }
        insertionIndex = addIndex + 1;
    }

    let v = vertices.getObjects();
    let lat = point != null ? point.lat : v[addIndex].getGeometry().lat * (1 - insertRatio) + v[nextIndex].getGeometry().lat * insertRatio;
    let lng = point != null ? point.lng : v[addIndex].getGeometry().lng * (1 - insertRatio) + v[nextIndex].getGeometry().lng * insertRatio;
    // add a new vertice
    logger.debug("Insert new point at " + lat + ", " + lng + (insertBefore ? " before" : " after") + " point index #" + addIndex);
    let newVertice = new H.map.Marker(
        { lat, lng },
        { icon: v[0].getIcon() });
    newVertice.draggable = true;
    newVertice.setData({ 'verticeIndex': insertionIndex });
    vertices.removeAll();
    v.splice(insertionIndex, 0, newVertice);
    vertices.addObjects(v);
    // update the indexes on the other vertices
    vertices.forEach((v, i) => {
        let data = v.getData();
        if (data['verticeIndex'] !== i) {
            data['verticeIndex'] = i
            v.setData(data);
        }
    });

    //add a new point to the polyline
    let geoLineString = polyline.getGeometry()
    geoLineString.insertPoint(insertionIndex, { lat, lng });
    polyline.setGeometry(geoLineString);

    return insertionIndex;
}

/**
 * Insert a point on the route
 * 
 * @param {H.map.Polyline} polyline the route polyline
 * @param {H.map.Group} vertices the points on the route
 * @param {H.geo.Point} point where to add the new point
 */
function insertRoutePointAtLocation(polyline, vertices, point) {
    // the specified point should lie within close proximity to the polyline
    let matchIndex = -1;
    let matchDistance = 0.0;
    let v = vertices.getObjects();

    for (let i = 0; i < v.length - 1; i++) {
        // find the closest route segment to the point
        let distanceToLineSegment = distanceToSegment(v[i].getGeometry(), v[i + 1].getGeometry(), point);
        if ((matchIndex < 0) || (distanceToLineSegment < matchDistance)) {
            matchIndex = i;
            matchDistance = distanceToLineSegment;
        }
    }
    if (matchIndex < 0) {
        // should not happen
        logger.warn("Location could not be matched to any route section: " + point);
        return;
    }

    logger.debug("Location found between route indexes " + matchIndex + " & " + (matchIndex + 1) + " : " + point);
    // check how close the new point is to the segment endpoints
    let distanceStartToPoint = v[matchIndex].getGeometry().distance(point),
        distanceEndToPoint = v[matchIndex + 1].getGeometry().distance(point),
        minInsertionDistance = 3;
    if (distanceStartToPoint < minInsertionDistance || distanceEndToPoint < minInsertionDistance) {
        logger.debug("Not inserting point (too close to existing point)");
    } else {
        // determine where to insert the new point into the matched segment
        let segmentLength = v[matchIndex].getGeometry().distance(v[matchIndex + 1].getGeometry());
        let distanceStartToIntersect = Math.sqrt(Math.pow(distanceStartToPoint, 2) - Math.pow(matchDistance, 2));
        let intersectRatio = distanceStartToIntersect / segmentLength;
        insertRoutePointAtIndex(polyline, vertices, matchIndex, false, null, intersectRatio);
    }
}

/**
 * Calculate the shortest distance from a point to a line segment
 * @param {*} segmentStart the start of the line segment
 * @param {*} segmentEnd the end of the line segment
 * @param {*} point the point of interest
 * @returns {number} the shortest distance from the point to the line segment
 */
function distanceToSegment(segmentStart, segmentEnd, point) {
    let A = segmentStart,                       // start of the segment
        B = segmentEnd,                         // end of the segment
        C = point;                              // the point of interest
    let distanceAC = A.distance(C),             // distance from the start of the segment to the point
        distanceBC = B.distance(C),             // distance from the end of the segment to the point
        //distanceAB = A.distance(B),             // distance from the start of the segment to the end of the segment
        bearingAC = calculateBearing(A, C),     // bearing from the start of the segment to the point
        bearingAB = calculateBearing(A, B),     // bearing from the start of the segment to the end of the segment
        bearingBC = calculateBearing(B, C),     // bearing from the end of the segment to the point
        bearingBA = calculateBearing(B, A);     // bearing from the end of the segment to the start of the segment
    let angleBAC = Math.abs(bearingAB - bearingAC),
        angleABC = Math.abs(bearingBA - bearingBC);
    if (angleBAC > 180)
        angleBAC = Math.abs(360 - angleBAC);    // obtuse -> acute
    if (angleABC > 180)
        angleABC = Math.abs(360 - angleABC);    // obtuse -> acute

    let distanceToLineSegment;                // shortest distance from the point to the segment
    if (angleBAC >= 90) {
        // point lies behind the start of the segment - just use the distance to the starting point
        distanceToLineSegment = distanceAC;
    } else if (angleABC >= 90) {
        // point lies after the end of the segment - just use the distance to the end point
        distanceToLineSegment = distanceBC;
    } else {
        // The point should be perpendicular to the line segment
        // Calculate the cross-track distance - refer to http://www.movable-type.co.uk/scripts/latlong.html
        let earthRadius = 6371000;
        distanceToLineSegment = Math.abs(Math.asin(Math.sin(distanceAC / earthRadius) * Math.sin(toRadians(bearingAC - bearingAB))) * earthRadius);
    }
    //logger.debug("DistAB: " + distanceAB + "m, DistToSegment: " + distanceToLineSegment + "m, DistA: "
    //    + distanceAC + "m, DistB: " + distanceBC + "m, angleBAC: " + angleBAC + "deg, angleABC: " + angleABC + "deg");
    return distanceToLineSegment;
}

/**
 * Add a stop/waypoint to the route
 * 
 * @param {H.map.Group} vertices the points on the route
 * @param {H.map.Group} stops the group of stop Map Marker objects
 * @param {number} addIndex the index into the list of points on the route where the stop whould be added
 * @param {boolean} isWaypoint true if this is a waypoint, else it is an actual bus stop
 * @returns {number} the index of the new stop 
 */
function addRouteStop(vertices, stops, addIndex, isWaypoint) {
    let stopIndexes = createStopCoordinateIndexList(vertices.getObjects(), stops.getObjects());
    if (!stopIndexes || stopIndexes.includes(addIndex)) {
        logger.info("Not inserting waypoint (Stop indexes not available or there is already a stop at this point)");
        return -1;
    }
    logger.info("Add " + (isWaypoint ? "waypoint" : "stop") + " at point #" + addIndex);
    // Add the marker approximately 5 metres to the left of the point on the road
    let bearingStart = (addIndex > 0) ? addIndex - 1 : 0;
    let bearing = calculateBearing(vertices.getObjects()[bearingStart].getGeometry(),
        vertices.getObjects()[bearingStart + 1].getGeometry()) - 90;
    let markerLocation = vertices.getObjects()[addIndex].getGeometry().walk(bearing, 5);
    let stopData = null;
    // create a new map marker
    stopData = {};
    if (!isWaypoint) {
        stopData.lat = markerLocation.lat;
        stopData.lng = markerLocation.lng;
        stopData.stopName = "New stop";
    }
    let marker = createStopMarker(markerLocation, stopData, -1, true);

    // insert the stop/waypoint into the stop list in order
    let newStopIndex = stopIndexes.length;
    for (let i = 0; i < stopIndexes.length; i++) {
        if (addIndex < stopIndexes[i]) {
            newStopIndex = i;
            break;
        }
    }
    let s = stops.getObjects();
    s.splice(newStopIndex, 0, marker);

    // fix the stop sequence numbers etc
    updateStopSequenceNumbers(s);

    stops.removeAll();
    stops.addObjects(s);

    return newStopIndex;
}

/**
 * Delete a stop/waypoint from the route
 * 
 * @param {H.map.Group} stops the group of stop Map Marker objects
 * @param {number} index the index of the stop to be deleted
 */
function deleteRouteStop(stops, index) {
    let s = stops.getObjects();
    if (index >= 0 && index < s.length) {
        let stopData = s[index].getData();
        if (isBusStop(stopData)) {
            logger.info("Delete stop, sequence number " + stopData.sequenceNo + " (index #" + index + ")");
        } else {
            logger.info("Delete waypoint (index #" + index + ")");
        }
        s.splice(index, 1);
        updateStopSequenceNumbers(s);
        stops.removeAll();
        stops.addObjects(s);
    }
}

/**
 * Prepare to drag a marker on the map
 * 
 * @param {H.Map} map the map instance
 * @param {H.map.Object} target the event target
 * @param {H.mapevents.Pointer} pointer the current pointer on the map
 */
function dragMarkerInit(map, target, pointer) {
    let targetPosition = map.geoToScreen(target.getGeometry());

    target['offset'] = new H.math.Point(pointer.viewportX - targetPosition.x, pointer.viewportY - targetPosition.y);
}

/**
 * Drag a marker on the map
 * 
 * @param {H.Map} map the map instance
 * @param {H.map.Object} target the event target
 * @param {H.mapevents.Pointer} pointer the current pointer on the map
 * @param {H.map.Polyline | null} polyline the route polyline
 */
function dragMarker(map, target, pointer, polyline) {
    let geoPoint = map.screenToGeo(pointer.viewportX - target['offset'].x, pointer.viewportY - target['offset'].y);

    // set new position for vertice marker
    target.setGeometry(geoPoint);

    // set new position for polyline's vertice
    if (polyline) {
        let geoLineString = polyline.getGeometry()
        geoLineString.removePoint(target.getData()['verticeIndex']);
        geoLineString.insertPoint(target.getData()['verticeIndex'], geoPoint);
        polyline.setGeometry(geoLineString);
    }
}


/**
 * Add event listeners for the route
 * 
 * @param {H.Map} map the map instance
 * @param {H.ui.UI} ui the UI instance
 * @param {H.map.Polyline} polyline the route polyline
 * @param {H.map.Group} vertices the points on the route
 * @param {H.map.Group} stops the group of stop Map Marker objects
 * @param {boolean} addStopListeners true if we add event listeners to the stops group
 * @param {function} showStopDetailsCallback callback function to edit the details of a stop
 * @param {function} drawRouteCallback callback function to manage route drawing functionality
 */
function addRouteEventListeners(map, ui, polyline, vertices, stops, addStopListeners, showStopDetailsCallback, drawRouteCallback) {
    // event listener to change the cursor if mouse position is over the polyline
    polyline.addEventListener('pointerenter', function (evt) {
        let drawRouteEnabled = (drawRouteCallback(false) != null);
        // only change the cursor if we are in edit mode and not drawing the route
        document.body.style.cursor = (drawRouteEnabled ? 'copy' : vertices.getVisibility() ? 'crosshair' : 'default');
    }, true);

    // event listener to change the cursor to default if mouse leaves the polyline
    polyline.addEventListener('pointerleave', function (evt) {
        let drawRouteEnabled = (drawRouteCallback(false) != null);
        document.body.style.cursor = (drawRouteEnabled ? 'copy' : 'default');
    }, true);


    // event listener for clicks on the route polyline
    polyline.addEventListener('tap', function (evt) {
        let pointer = evt.currentPointer,
            drawRouteEnabled = (drawRouteCallback(false) != null),
            geoPoint = map.screenToGeo(pointer.viewportX, pointer.viewportY);
        // only insert a point if we are in edit mode
        if (!drawRouteEnabled && vertices.getVisibility() && (pointer.type !== "mouse" || pointer.button !== H.mapevents.Pointer.Button.RIGHT)) {
            insertRoutePointAtLocation(polyline, vertices, geoPoint);
        }
    }, true);

    // event listener for vertice markers group to change the cursor to pointer if mouse position enters this group
    vertices.addEventListener('pointerenter', function (evt) {
        document.body.style.cursor = 'pointer';
    }, true);

    // event listener for vertice markers group to change the cursor to default if mouse leaves this group
    vertices.addEventListener('pointerleave', function (evt) {
        let drawRouteEnabled = (drawRouteCallback(false) != null);
        document.body.style.cursor = (drawRouteEnabled ? 'copy' : 'default');
    }, true);

    // event listener for vertice markers group to set the offset when dragging the markers
    vertices.addEventListener('dragstart', function (evt) {
        // prepare for the marker to be dragged
        dragMarkerInit(map, evt.target, evt.currentPointer);
    }, true);

    // event listener for vertice markers group to resize the geo polyline object if dragging over markers
    vertices.addEventListener('drag', function (evt) {
        // drag the marker
        dragMarker(map, evt.target, evt.currentPointer, polyline);

        // stop propagating the drag event, so the map doesn't move
        evt.stopPropagation();
    }, true);

    vertices.addEventListener('contextmenu', function (evt) {
        // Get geo coordinates from the screen coordinates.
        let coord = map.screenToGeo(evt.viewportX, evt.viewportY),
            pointIndex = evt.target.getData()['verticeIndex'],
            stopIndexes = createStopCoordinateIndexList(vertices.getObjects(), stops.getObjects()),
            drawRouteEnabled = (drawRouteCallback(false) != null),
            addStopDisabled = !stopIndexes || stopIndexes.includes(pointIndex) || drawRouteEnabled,
            insertPointDisabled = pointIndex >= (vertices.getObjects().length - 1) || drawRouteEnabled,
            deletePointDisabled = (vertices.getObjects().length <= 2) || drawRouteEnabled || addStopDisabled,
            extendRouteDisabled = ((pointIndex > 0) && (pointIndex < (vertices.getObjects().length - 1))) || drawRouteEnabled;

        // remove existing bubbles
        removeUiBubbles(ui);

        evt.items.push(
            // Create a menu item, that has only a label, which displays the current coordinates.
            new H.util.ContextItem({
                label: [
                    Math.abs(coord.lat.toFixed(6)) + ((coord.lat > 0) ? 'N' : 'S'),
                    Math.abs(coord.lng.toFixed(6)) + ((coord.lng > 0) ? 'E' : 'W')
                ].join(' ')
            }),
            new H.util.ContextItem({
                label: 'Insert new point',
                callback: function () {
                    insertRoutePointAtIndex(polyline, vertices, pointIndex, false, null, 0.5);
                },
                disabled: insertPointDisabled
            }),
            new H.util.ContextItem({
                label: 'Delete point',
                callback: function () {
                    logger.debug("Delete point #" + pointIndex);
                    // remove the point from the polyline
                    let geoLineString = polyline.getGeometry()
                    geoLineString.removePoint(pointIndex);
                    polyline.setGeometry(geoLineString);
                    // remove the vertice
                    vertices.removeObject(evt.target);
                    // update the indexes on the remaining vertices
                    vertices.forEach((v, i) => {
                        let data = v.getData();
                        if (data['verticeIndex'] !== i) {
                            // logger.debug("Updating vertice index: " + data['verticeIndex'] + " -> " + i);
                            data['verticeIndex'] = i
                            v.setData(data);
                        }
                    });
                },
                disabled: deletePointDisabled
            }),
            H.util.ContextItem.SEPARATOR,
            new H.util.ContextItem({
                label: 'Add stop here',
                callback: function () {
                    let newStopIndex = addRouteStop(vertices, stops, pointIndex, false);
                    if (newStopIndex >= 0) {
                        let stopDetails = stops.getObjects()[newStopIndex].getData();
                        // add the index of the stop for later use
                        stopDetails.index = newStopIndex;
                        stopDetails.isNew = true;
                        showStopDetailsCallback(true, true, stopDetails);
                    }
                },
                disabled: addStopDisabled
            }),
            new H.util.ContextItem({
                label: 'Add waypoint here',
                callback: function () {
                    addRouteStop(vertices, stops, pointIndex, true);
                },
                disabled: addStopDisabled
            }),
            H.util.ContextItem.SEPARATOR,
            new H.util.ContextItem({
                label: 'Draw route',
                callback: function () {
                    // extend the route - enable drawing mode
                    drawRouteCallback(true, pointIndex);
                    document.body.style.cursor = 'copy';
                },
                disabled: extendRouteDisabled
            }),
            new H.util.ContextItem({
                label: 'Exit drawing mode',
                callback: function () {
                    // exit drawing mode
                    drawRouteCallback(true, null);
                    document.body.style.cursor = 'default';
                },
                disabled: !drawRouteEnabled
            })
        );
    });

    if (!stops || !addStopListeners) {
        return;
    }

    // Add event listeners for the stop markers
    // event listener to change the cursor to pointer if mouse position enters this group
    stops.addEventListener('pointerenter', function (evt) {
        document.body.style.cursor = 'grab';
    }, true);

    // event listener to change the cursor to default if mouse leaves this group
    stops.addEventListener('pointerleave', function (evt) {
        let drawRouteEnabled = (drawRouteCallback(false) != null);
        document.body.style.cursor = (drawRouteEnabled ? 'copy' : 'default');
    }, true);


    // add 'tap' event listener, that opens info bubble, to the group of stops
    stops.addEventListener('tap', function (evt) {
        // event target is the marker itself, group is a parent event target
        // for all objects that it contains

        // remove existing bubbles
        removeUiBubbles(ui);

        // Leave right mouse clicks for the context menu handler
        if (evt.currentPointer.button !== H.mapevents.Pointer.Button.RIGHT) {
            let stopDetails = evt.target.getData();
            if (isBusStop(stopDetails)) {
                stopDetails.isNew = false;
                showStopDetailsCallback(true, false, stopDetails);
            } else {
                // show info bubble
                let labelColor = "darkblue";
                let bubbleContent = '<div><span style=color:' + labelColor + '><b>Waypoint</b></span></div>';
                ui.addBubble(new H.ui.InfoBubble(evt.target.getGeometry(), { content: bubbleContent }));
            }
        }
    }, true);

    // Subscribe to "contextmenu" events
    stops.addEventListener('contextmenu', function (evt) {
        if (evt.target.draggable) {
            // Get geo coordinates from the screen coordinates.
            let coord = map.screenToGeo(evt.viewportX, evt.viewportY);
            evt.items.push(
                // Create a menu item, that has only a label, which displays the current coordinates.
                new H.util.ContextItem({
                    label: [
                        Math.abs(coord.lat.toFixed(6)) + ((coord.lat > 0) ? 'N' : 'S'),
                        Math.abs(coord.lng.toFixed(6)) + ((coord.lng > 0) ? 'E' : 'W')
                    ].join(' ')
                })
            );

            let s = stops.getObjects();
            let stopIndex = -1;
            for (let i = 0; i < s.length; i++) {
                if (evt.target.getGeometry().equals(s[i].getGeometry())) {
                    stopIndex = i;
                    break;
                }
            }

            if (isBusStop(evt.target.getData())) {
                evt.items.push(
                    new H.util.ContextItem({
                        label: 'Edit stop',
                        callback: function () {
                            let stopDetails = evt.target.getData();
                            // add the index of the stop for later use
                            stopDetails.index = stopIndex;
                            stopDetails.isNew = false;
                            showStopDetailsCallback(true, true, stopDetails);
                        }
                    }),
                    new H.util.ContextItem({
                        label: 'Delete stop',
                        callback: function () {
                            deleteRouteStop(stops, stopIndex);
                        }
                    })
                );
            } else {
                evt.items.push(
                    new H.util.ContextItem({
                        label: 'Delete waypoint',
                        callback: function () {
                            deleteRouteStop(stops, stopIndex);
                        }
                    })
                );
            }
        }
    });

    // Listen to the drag start event for stop/waypoint markers
    stops.addEventListener('dragstart', function (evt) {
        dragMarkerInit(map, evt.target, evt.currentPointer);
        // // save the current route and stop indexes for later use
        // createStopCoordinateIndexList(vertices.getObjects(), stops.getObjects(), true);
    }, true);

    // Listen to the drag event for stop/waypoint markers and move the position of the marker as necessary
    stops.addEventListener('drag', function (evt) {
        // set new position for vertice marker
        dragMarker(map, evt.target, evt.currentPointer, null);
        // stop propagating the drag event, so the map doesn't move
        evt.stopPropagation();
    }, true);

    // Listen to the drag end event for stop/waypoint markers
    stops.addEventListener('dragend', function (evt) {
        // // check if the stop order needs updating after the stop was moved
        // let newRouteIndex = createStopCoordinateIndexList(vertices.getObjects(), [evt.target])[0],
        //     oldStopIndex = evt.target.getData().stopIndex,
        //     stopList = stops.getObjects(),
        //     stopCount = stops.getObjects().length,
        //     insertionIndex = null;

        // // check if the stop was dragged in front of earlier stops
        // for (let s = 0; s < oldStopIndex; s++) {
        //     if (newRouteIndex < stops.getObjects()[s].getData().routeIndex) {
        //         logger.debug("Stop dragged to before stop index " + s);
        //         insertionIndex = s;
        //         break;
        //     }
        // }

        // // check if the stop was dragged to after later stops
        // if (insertionIndex === null) {
        //     for (let s = stopCount - 1; s > oldStopIndex; s--) {
        //         if (newRouteIndex > stops.getObjects()[s].getData().routeIndex) {
        //             logger.debug("Stop dragged to after stop index " + s);
        //             insertionIndex = s;
        //             break;
        //         }
        //     }
        // }

        // // do we need to rearrange the stop order?
        // if (insertionIndex !== null) {
        //     // remove it and reinsert at the new location
        //     let removedStop = stopList.splice(oldStopIndex, 1);
        //     stopList.splice(insertionIndex, 0, removedStop[0]);
        //     // fix the stop sequence numbers etc
        //     updateStops(stopList);
        //     stops.removeAll();
        //     stops.addObjects(stopList);

        // }

        // TODO - check if the stop was moved next to another stop

    }, true);
}

function removeUiBubbles(ui) {
    // remove existing bubbles from the UI
    for (const b of ui.getBubbles()) {
        ui.removeBubble(b);
    }
}

/**
 * Add map event listeners
 * @param {H.Map} map the map instance
 * @param {H.ui.UI} ui the UI instance
 * @param {function} zoomToRouteCallback callback function to request the map zoom to the currently loaded route
 * @param {function} clearMapCallback callback function to request the map to be cleared
 * @param {function} drawRouteCallback callback function to manage route drawing functionality
 * @param {function} editRouteDetailsCallback callback function to request the route details to be edited
 * @param {function} exportCalculatedRouteCallback callback function to export the calculated route details
 */
function addMapEventListeners(map, ui, zoomToRouteCallback, clearMapCallback, drawRouteCallback, editRouteDetailsCallback, exportCalculatedRouteCallback) {
    map.addEventListener('contextmenu', function (evt) {
        if (evt.target !== map) {
            return;
        }
        // remove existing bubbles
        removeUiBubbles(ui);

        // Get geo coordinates from the screen coordinates.
        let coord = map.screenToGeo(evt.viewportX, evt.viewportY);

        evt.items.push(
            // Create a menu item, that has only a label, which displays the current coordinates.
            new H.util.ContextItem({
                label: [
                    Math.abs(coord.lat.toFixed(6)) + ((coord.lat > 0) ? 'N' : 'S'),
                    Math.abs(coord.lng.toFixed(6)) + ((coord.lng > 0) ? 'E' : 'W')
                ].join(' ')
            }),
            // Create an item, that will change the map center when clicking on it.
            new H.util.ContextItem({
                label: 'Center map here',
                callback: function () {
                    map.setCenter(coord, true);
                }
            })
        );

        // add optional items based on the objects loaded on the map
        for (let obj of map.getObjects()) {
            if (obj.type === H.map.Object.Type.GROUP) {
                if (obj.getData() === mapGroupNamePlannedRoute) {
                    // Zoom to view the current route
                    evt.items.push(new H.util.ContextItem({
                        label: 'Zoom to route',
                        callback: function () {
                            zoomToRouteCallback();
                        }
                    }));

                    if (obj.getObjects().length >= 2) {
                        // the vertices are visible, so we're in edit mode
                        evt.items.push(new H.util.ContextItem({
                            label: (obj.getObjects()[1].getVisibility() ? 'Edit' : 'View') + ' route details',
                            callback: function () {
                                editRouteDetailsCallback(obj.getObjects()[1].getVisibility());
                            }
                        }));
                    }
                } else if (obj.getData() === mapGroupNameCalculatedRoute) {
                    // Remove the calculated route from the map
                    evt.items.push(new H.util.ContextItem({
                        label: 'Clear calculated route',
                        callback: function () {
                            clearMapCallback(false, false, true);
                        }
                    }));
                    // Export the calculated route geometry
                    evt.items.push(new H.util.ContextItem({
                        label: 'Export calculated route geometry',
                        callback: function () {
                            exportCalculatedRouteCallback();
                        }
                    }));
                }
            }
        }
        if (drawRouteCallback(false) != null) {
            evt.items.push(new H.util.ContextItem({
                label: 'Exit drawing mode',
                callback: function () {
                    drawRouteCallback(true, null);
                    document.body.style.cursor = 'default';
                }
            }));
        }
    });

    // event listener for clicks on the map
    map.addEventListener('tap', function (evt) {
        let //target = evt.target,
            pointer = evt.currentPointer,
            geoPoint = map.screenToGeo(pointer.viewportX, pointer.viewportY);
        // Leave right mouse clicks for the context menu handler
        if (evt.currentPointer.type !== "mouse" || evt.currentPointer.button !== H.mapevents.Pointer.Button.RIGHT) {
            let pointIndex = drawRouteCallback(false);
            if (pointIndex != null) {
                for (let obj of map.getObjects()) {
                    // find the object group containing the planned route
                    if (obj.type === H.map.Object.Type.GROUP && obj.getObjects().length >= 2 && obj.getData() === mapGroupNamePlannedRoute) {
                        let polyline = obj.getObjects()[0],
                            vertices = obj.getObjects()[1];
                        // add a point to the route
                        let insertionIndex = insertRoutePointAtIndex(polyline, vertices, pointIndex, (pointIndex === 0), geoPoint);
                        drawRouteCallback(true, insertionIndex);
                        break;
                    }
                }
            }
        }
    }, true);
}


/**
 * Initialise the previous route values so we can detect if the route is modified later
 * 
 * @param {H.map.Polyline} polyline the current route polyline
 * @param {H.map.Group} vertices the current points on the route
 * @param {H.map.Group} stops the current stops on the route
 * @param {{*}} details the route details
 */
function initialisePreviousRouteValues(polyline, vertices, stops, details) {
    // create a deep copy of each vertice
    let verticesCopy = new H.map.Group();
    for (const v of vertices.getObjects()) {
        let marker = new H.map.Marker(
            { lat: v.getGeometry().lat, lng: v.getGeometry().lng },
            { icon: v.getIcon() });
        marker.setData(v.getData());
        marker.draggable = true;
        verticesCopy.addObject(marker);
    }

    // create a deep copy of each stop
    let stopsCopy = new H.map.Group();
    for (const stop of stops.getObjects()) {
        let marker = new H.map.Marker(
            { lat: stop.getGeometry().lat, lng: stop.getGeometry().lng },
            { icon: stop.getIcon() });
        let data = stop.getData();
        let dataCopy = {};
        for (const key in data) {
            if (data.hasOwnProperty(key)) {
                dataCopy[key] = data[key];
            }
        }
        marker.setData(dataCopy);
        marker.setVolatility(true);
        marker.draggable = false;
        stopsCopy.addObject(marker);
    }

    // deep copy the details object
    let detailsCopy = JSON.parse(JSON.stringify(details));

    return { vertices: verticesCopy, stops: stopsCopy, details: detailsCopy };
}

/**
 * Restore previous route values after they have been modified
 * 
 * @param {H.map.Polyline} polyline the current route polyline
 * @param {H.map.Group} vertices the current points on the route
 * @param {H.map.Group} stops the current stops on the route
 * @param {{*}} details the current route details
 * @param {H.map.Group} prevVertices the previous points on the route
 * @param {H.map.Group} prevStops the previous stops on the route
 * @param {{*}} prevDetails the previous route details
*/
function restorePreviousRouteValues(polyline, vertices, stops, details, prevVertices, prevStops, prevDetails) {
    // restore the copy of the previous route vertices and stops
    stops.removeAll();
    stops.addObjects(prevStops.getObjects());
    vertices.removeAll();
    vertices.addObjects(prevVertices.getObjects());

    // reconstruct the route polyline geometry from the set of vertices
    let lineString = new H.geo.LineString();
    for (const v of vertices.getObjects()) {
        lineString.pushPoint({ lat: v.getGeometry().lat, lng: v.getGeometry().lng });
    }
    polyline.setGeometry(lineString);

    // restore the previous route details
    for (const key in prevDetails) {
        details[key] = prevDetails[key];
    }
}

/**
 * Check if the currently loaded route has been modified
 * 
 * @param {H.map.Group} newVertices the current points on the route
 * @param {H.map.Group} newStops the current stops on the route
 * @param {{*}} newDetails the current route details
 * @param {H.map.Group} prevVertices the previous points on the route
 * @param {H.map.Group} prevStops the previous stops on the route
 * @param {{*}} prevDetails the previous route details
*/
function wasRouteModified(newVertices, newStops, newDetails, prevVertices, prevStops, prevDetails) {
    let nv = newVertices.getObjects(),
        pv = prevVertices.getObjects(),
        ns = newStops.getObjects(),
        ps = prevStops.getObjects();

    // check if any route points or stops have been added or removed
    if (nv.length !== pv.length || ns.length !== ps.length) {
        return true;
    }

    // check for changes to the route geometry
    for (let i = 0; i < nv.length; i++) {
        if (!nv[i].getGeometry().equals(pv[i].getGeometry())) {
            return true;
        }
    }

    // check for changes to the stop locations & metadata
    for (let i = 0; i < ns.length; i++) {
        if (!ns[i].getGeometry().equals(ps[i].getGeometry())) {
            return true;
        }
        if (JSON.stringify(ns[i].getData()) !== JSON.stringify(ps[i].getData())) {
            return true;
        }
    }

    // check for changes to the route details
    if (JSON.stringify(newDetails) !== JSON.stringify(prevDetails)) {
        return true;
    }

    return false;
}

/**
 * Check for issues with the route
 * @param {H.map.Group} vertices the points on the route geometry
 * @param {H.map.Group} stops the stops on the route
 * @returns {string[]} an array of error messages for issues found with the route
 */
function validateRoute(vertices, stops) {
    let routeIssueList = [];
    // Check the number of points in the route geometry
    if (vertices.getObjects().length > maxRouteWaypoints) {
        routeIssueList.push("Route has too many points (" + vertices.getObjects().length + ") - reduce to " + maxRouteWaypoints + " or less");
    }

    // Create the stop index list and add the index details to the stop objects
    let stopIndexes = createStopCoordinateIndexList(vertices.getObjects(), stops.getObjects(), true);
    if (!stopIndexes.includes(0)) {
        routeIssueList.push("The first point on the route should be mapped to a bus stop");
    }
    if (!stopIndexes.includes(vertices.getObjects().length - 1)) {
        routeIssueList.push("The last point on the route should be mapped to a bus stop");
    }

    // Check if stops are in a valid location in relation to other stops and the route geometry
    for (let i = 0; i < stops.getObjects().length; i++) {
        let stop = stops.getObjects()[i],
            stopData = stops.getObjects()[i].getData();

        // Check the distance from the previous stop
        let distanceToPreviousStop = (i > 0 ? stop.getGeometry().distance(stops.getObjects()[i - 1].getGeometry()) : 99999);
        // let distanceToPreviousStopRoutePoint = (i > 0 ?
        //     stop.getGeometry().distance(vertices.getObjects()[stops.getObjects()[i - 1].getData().routeIndex].getGeometry()) : 99999);
        let routeIndex = stopData.routeIndex;
        let distanceToRouteA = routeIndex > 0 ? distanceToSegment(vertices.getObjects()[routeIndex - 1].getGeometry(),
            vertices.getObjects()[routeIndex].getGeometry(), stop.getGeometry()) : 99999,
            distanceToRouteB = routeIndex < (vertices.getObjects().length - 1) ? distanceToSegment(vertices.getObjects()[routeIndex].getGeometry(),
                vertices.getObjects()[routeIndex + 1].getGeometry(), stop.getGeometry()) : 99999;
        let distanceToRouteSegment = Math.min(distanceToRouteA, distanceToRouteB);
        let messagePrefix = isBusStop(stopData) ? "Stop #" + stopData.sequenceNo + " (" + stopData.stopName + ")" : "Waypoint";
        //logger.debug(i, routeIndex, distanceToPreviousStop, distanceToPreviousStopRoutePoint, stopData.distanceFromPointOnRoute, distanceToRouteSegment);

        // the first and last waypoints should be bus stops
        if (i === 0) {
            if (!isBusStop(stopData))
                routeIssueList.push("The first waypoint on the route should be a bus stop");
            else if (stopData.routeIndex !== 0)
                routeIssueList.push(messagePrefix + " should be mapped to the first point on the route");
        } else if (i === (stops.getObjects().length - 1)) {
            if (!isBusStop(stopData))
                routeIssueList.push("The last waypoint on the route should be a bus stop");
            else if (stopData.routeIndex !== (vertices.getObjects().length - 1))
                routeIssueList.push(messagePrefix + " should be mapped to the last point on the route");
        }

        if (distanceToRouteSegment > maxStopDistanceFromRouteSegment) {
            // Stop is too far from the route geometry
            routeIssueList.push(messagePrefix + " is too far from the route (" + distanceToRouteSegment.toFixed(1) + "m)");
        }
        else if (distanceToPreviousStop < minStopInterval) {
            // Stops are too close together
            routeIssueList.push(messagePrefix + " is too close to the previous stop/waypoint (" + distanceToPreviousStop.toFixed(1) + "m)");

            // TODO - deal with stops vs waypoints
        } else if (stopData.distanceFromPointOnRoute > maxStopDistanceFromRoutePoint) {
            // Stop is too far from a point on the route geometry
            routeIssueList.push(messagePrefix + " is too far from a point on the route (" + stopData.distanceFromPointOnRoute.toFixed(1) + "m)");
        }
    }

    return routeIssueList;
}

/**
 * Set a group of objects as draggable/not draggable
 * @param {H.map.Group} objectGroup 
 * @param {boolean} draggable 
 */
function setDraggable(objectGroup, draggable) {
    for (const obj of objectGroup.getObjects()) {
        obj.draggable = draggable;
    }
}

/**
 * Set the map's viewport to make the object visible
 * 
 * @param {H.Map} map the map instance
 * @param {H.map.Group} object the object to zoom to
 */
function zoomToObject(map, object) {
    if (object) {
        let bb = object.getBoundingBox();
        map.getViewModel().setLookAtData({ bounds: bb }, false);
    }
}

/**
 * Create a custom map UI control
 * @see https://stackoverflow.com/a/53722757
 * @param {H.ui.UI} ui the UI instance
 * @param {string} name the name of the control to add
 * @param {string} svgIcon the icon to use (as html mark-up) for the control
 * @param {string} alignment the alignment of the control within the map view port
 * @param {function} onStateChangeCallback function to call when the state of the control changes
 * @param {string} title the title of the control (for use as a tooltip)
 * @returns {H.ui.Control} the new control
 */
function createCustomMapUiControl(ui, name, svgIcon, alignment, onStateChangeCallback, title) {
    let inherits = function (childCtor, parentCtor) {
        function tempCtor() { } tempCtor.prototype = parentCtor.prototype; childCtor.superClass_ = parentCtor.prototype; childCtor.prototype = new tempCtor(); childCtor.prototype.constructor = childCtor; childCtor.base = function (me, methodName, var_args) {
            let args = new Array(arguments.length - 2);
            for (let i = 2; i < arguments.length; i++) {
                args[i - 2] = arguments[i];
            }
            return parentCtor.prototype[methodName].apply(me, args);
        };
    };

    let customUI = function (opt_options) {
        let options = opt_options || {};
        H.ui.Control.call(this);

        // create a button element   
        this.newBtn_ = new H.ui.base.Button({
            'label': svgIcon,
            'onStateChange': onStateChangeCallback
        });

        //add the buttons as this control's children   
        this.addChild(this.newBtn_);
        this.setAlignment(options['alignment'] || 'top-right');
        this.options_ = options;
    };
    inherits(customUI, H.ui.Control);

    let newControl = new customUI({ 'alignment': alignment });
    ui.addControl(name, newControl);
    // Set the title (if provided)
    if (title) {
        ui.getControl(name).getElement().title = title;
    }
}

/**
 * Calculate a route based on a set of waypoints
 * 
 * @param {H.service.RoutingService | H.service.RoutingService8} router the routing service
 * @param {H.map.Marker[]} routeCoordinates the list of route coordinates
 * @param {H.map.Marker[]} stopCoordinates the list of stop locations
 * @param {function} routeCalculatedCallBack function to call when route calculation is complete
 */
function calculateRoute(router, routeCoordinates, stopCoordinates, routeCalculatedCallBack) {
    // Create the parameters for the routing request
    let routingParameters = {
        //'routingMode': 'fast',
        'transportMode': 'bus',
        'traffic[mode]': 'disabled',
        'alternatives': '0',
        // Include the route shape in the response
        //'return': 'polyline,turnByTurnActions,travelSummary'
        'return': 'polyline,travelSummary'
    };

    let stopIndexes = createStopCoordinateIndexList(routeCoordinates, stopCoordinates);
    let viaPoints = [];
    for (let index = 0; index < routeCoordinates.length; index++) {
        let pos = routeCoordinates[index].getGeometry();
        let point = pos.lat + ',' + pos.lng;
        if (index === 0) {
            // The starting point of the route
            routingParameters.origin = point;
        } else if (index === (routeCoordinates.length - 1)) {
            // The end of the route
            routingParameters.destination = point;
        } else {
            // configure thispoint as a pass-through waypoint if it is not a stop
            if (!stopIndexes.includes(index)) {
                point += '!passThrough=true';
            }
            viaPoints.push(point);
        }
    }

    if (viaPoints.length > 0) {
        routingParameters.via = new H.service.Url.MultiValueQueryParameter(viaPoints);
    }

    logger.info('Calculating route from ' + routingParameters.origin + ' to ' +
        routingParameters.destination + ' with ' + viaPoints.length + ' via waypoints');
    // calculate the route
    router.calculateRoute(routingParameters,
        function (result) {
            logger.info('Routing complete - ' + result.routes.length + ' route(s) available');
            result.notices.forEach((notice) => logger.warn('Routing notice: ' + notice.title));
            if (result.routes.length) {
                let routeSections = result.routes[0].sections;
                let multiLineString = new H.geo.MultiLineString([]);
                let routeVertices = [];
                let routeLength = 0;
                let routeDuration = 0;
                routeSections.forEach((section) => {
                    // Create a linestring to use as a point source for the route line
                    let linestring = H.geo.LineString.fromFlexiblePolyline(section.polyline);
                    linestring.eachLatLngAlt((lat, lng, alt, idx) => {
                        routeVertices.push({ lat: lat, lng: lng });
                    });
                    multiLineString.push(linestring);
                    routeDuration += section.travelSummary.baseDuration;
                    routeLength += section.travelSummary.length;
                });
                routeLength = Math.round(routeLength / 100) / 10;
                routeDuration = Math.round(routeDuration / 60);
                logger.info('Calculated route has ' + routeSections.length + ' section(s), length = ' +
                    routeLength + "km, expected duration = " + routeDuration + 'min');
                // // Create a polyline to display the route:
                // let routeLine = new H.map.Polyline(multiLineString, {
                //     style: { strokeColor: 'magenta', lineWidth: 8 }
                // });

                // Create an outline for the route polyline:
                let routeOutline = new H.map.Polyline(multiLineString, {
                    style: {
                        lineWidth: 8,
                        strokeColor: 'magenta',
                        lineTailCap: 'arrow-tail',
                        lineHeadCap: 'arrow-head'
                    }
                });
                // Create a patterned polyline:
                let routeArrows = new H.map.Polyline(multiLineString, {
                    style: {
                        lineWidth: 8,
                        fillColor: 'white',
                        strokeColor: 'rgba(255, 255, 255, 1)',
                        lineDash: [0, 2],
                        lineTailCap: 'arrow-tail',
                        lineHeadCap: 'arrow-head'
                    }
                });

                // Create a marker for the start point:
                let iconSize = routeEndPointsIconSize;  // pixels
                let startMarker = new H.map.Marker(routeSections[0].departure.place.location,
                    { icon: new H.map.Icon(green_dot, { size: { h: iconSize, w: iconSize }, anchor: { x: iconSize / 2, y: iconSize / 2 } }) });

                // Create a marker for the end point:
                let endMarker = new H.map.Marker(routeSections[routeSections.length - 1].arrival.place.location,
                    { icon: new H.map.Icon(red_dot, { size: { h: iconSize, w: iconSize }, anchor: { x: iconSize / 2, y: iconSize / 2 } }) });

                // Return the route polyline and the two markers
                // let routeGroup = new H.map.Group({ 'objects': [routeLine, startMarker, endMarker] });
                let routeGroup = new H.map.Group({
                    'objects': [routeOutline, routeArrows, startMarker, endMarker],
                    data: mapGroupNameCalculatedRoute
                });
                routeCalculatedCallBack({ route: routeGroup, length: routeLength, duration: routeDuration, vertices: routeVertices });
            } else {
                let errorMessage = 'Unable to calculate route';
                for (const notice of result.notices) {
                    if (notice.severity === 'critical') {
                        errorMessage = notice.title;
                        break;
                    }
                }
                routeCalculatedCallBack(null, errorMessage);
            }

        },
        function (error) {
            let msg = 'Error calculating route';
            logger.error(msg);
            routeCalculatedCallBack(null, msg);
        });
}

/**
 * Create a filtered version of the route polyline to reduce the number of points
 * 
 * @param {H.map.Marker[]} routeCoordinates the list of route coordinates
 * @param {H.map.Marker[]} stopCoordinates the list of stop locations
 * @param {number} numCycles the number of filter cycles to perform (default = 1)
 * @returns {{polyline:H.map.Polyline, verticeGroup:H.map.Group} | null}
 */
function createFilteredRoutePolyline(routeCoordinates, stopCoordinates, numCycles = 1) {
    // Process the list of route points and create a set of waypoints for route calculation
    //   - filter according to distance to reduce the number of waypoints
    //   - find a corresponding point on the route for each stop and ensure this is included
    // Assumptions:
    //  - the list of stops is in order, from first to last
    // let routeCoordinates = verticesGroup.getObjects();
    // let stopCoordinates = stopsGroup.getObjects();
    let stopIndexes = createStopCoordinateIndexList(routeCoordinates, stopCoordinates);
    if (!stopIndexes) {
        return null;
    }

    let waypointDistanceInterval = minRouteWaypointInterval;     // metres
    let previousWaypointLocation = routeCoordinates[0].getGeometry();
    let filteredRouteGeometry;
    let targetPointCount = routeCoordinates.length <= maxRouteWaypoints ? (routeCoordinates.length - 10) : maxRouteWaypoints;
    let cycleCount = 1;

    if (targetPointCount < stopCoordinates.length)
        targetPointCount = stopCoordinates.length;
    logger.debug('Route has ' + routeCoordinates.length + ' points, target is ' + targetPointCount + ' points');

    for (let x = 1; x <= 100; x++) {
        // increase distance interval each loop cycle until we fall within the allowed number of waypoints
        let minDistance = waypointDistanceInterval + ((x - 1) * 50);
        let stopOverCount = 0;
        let passThroughCount = 0;
        filteredRouteGeometry = [];

        for (let j = 0; j < routeCoordinates.length; j++) {
            let currentLocation = routeCoordinates[j].getGeometry();
            // add the following points as waypoints:
            //  - the first and last point
            //  - all stops
            //  - all others that are separated by the minimum distance
            let distanceFromPrevious = 0;
            if (j > 0) {
                distanceFromPrevious = previousWaypointLocation.distance(currentLocation);
            }
            if (j === 0 || j === (routeCoordinates.length - 1) || distanceFromPrevious >= minDistance || stopIndexes.includes(j)) {
                if (j === 0 || j === (routeCoordinates.length - 1) || stopIndexes.includes(j)) {
                    // Stopover waypoints
                    stopOverCount++;
                } else {
                    // Pass-through waypoints.  These tell the routing engine to avoid:
                    //  - Introducing a stop at the waypoint.
                    //  - Splitting the route into sections.
                    //  - Changing the direction of travel.

                    // Look ahead first - don't add this point if there is a stop coming up soon
                    let nextStopIndex = stopIndexes.find(element => element > j);
                    let nextStopDistance = currentLocation.distance(routeCoordinates[nextStopIndex].getGeometry());
                    if (nextStopDistance > minDistance) {
                        passThroughCount++;
                    } else {
                        continue;
                    }
                }
                filteredRouteGeometry.push(currentLocation);
                previousWaypointLocation = currentLocation;
            }
        }
        // check if we have removed enough waypoints
        logger.debug("Generated " + filteredRouteGeometry.length + " waypoints (" + stopOverCount + "," + passThroughCount + ") at a minimum distance interval of " + minDistance + "m");
        if (passThroughCount === 0) {
            return createRoutePolyline(filteredRouteGeometry);
        } else if (filteredRouteGeometry.length <= targetPointCount) {
            if (cycleCount >= numCycles || filteredRouteGeometry.length <= stopCoordinates.length) {
                break;
            } else {
                // Run another filter cycle, targeting fewer waypoints
                cycleCount++;
                targetPointCount = filteredRouteGeometry.length - 10;
                if (targetPointCount < stopCoordinates.length)
                    targetPointCount = stopCoordinates.length;
            }
        }
    }

    if (filteredRouteGeometry.length <= targetPointCount)
        return createRoutePolyline(filteredRouteGeometry);
    else
        return null;
}

/**
 * Create a list of indexes so that we know which points in the list of route coordinates correspond to a bus stop location
 * Assumptions - there should be a point on the route geometry that is reasonably close to each bus stop
 *             - the list of stops is in the correct order
 *
 * @param {H.map.Marker[]} routeCoordinates the list of route coordinates
 * @param {H.map.Marker[]} stopCoordinates the list of stop locations
 * @param {boolean} [addIndexesToStopProperties=false] true to add route and stop indexes to the stop properties
 * @returns {number[] | null} an array of indexes into the routeCoordinates list
 */
function createStopCoordinateIndexList(routeCoordinates, stopCoordinates, addIndexesToStopProperties = false) {
    let result = [];
    let routeIndex = 0;
    for (let s = 0; s < stopCoordinates.length; s++) {
        let minDistance = 9999999999.9;
        let minDistanceIndex = 0;
        // search through the route and find the point that is closest to this stop
        for (let r = routeIndex; r < routeCoordinates.length; r++) {
            let distanceToStop = routeCoordinates[r].getGeometry().distance(stopCoordinates[s].getGeometry());
            if (distanceToStop < minDistance) {
                minDistance = distanceToStop;
                minDistanceIndex = r;
                if (distanceToStop <= 3) {
                    // this point is very close so assume this is the correct point and stop searching
                    break;
                }
            }
        }
        // logger.verbose("Stop " + (s + 1) + " index = " + minDistanceIndex + ", distance = " + minDistance.toFixed(2) + "m; S: " +
        //     stopCoordinates[s].getGeometry().lat + "," + stopCoordinates[s].getGeometry().lng + "; R: " +
        //     routeCoordinates[minDistanceIndex].getGeometry().lat + "," + routeCoordinates[minDistanceIndex].getGeometry().lng);
        // add the index to the result list, then start searching for the next stop, starting from the next index (don't search the entire route again)
        result.push(minDistanceIndex);
        if (addIndexesToStopProperties) {
            let stopData = stopCoordinates[s].getData();
            stopData.stopIndex = s;
            stopData.routeIndex = minDistanceIndex;
            stopData.distanceFromPointOnRoute = minDistance;
            stopCoordinates[s].setData(stopData);
        }
        routeIndex = minDistanceIndex + 1;
    }

    // TODO - need to alert the user if a stop is too far from a point on the route

    logger.debug("createStopCoordinateIndexList: mapped " + result.length + "/" + stopCoordinates.length + " stops from route list of " + routeCoordinates.length + " points");
    return result;
}

/**
 * Prepare route values for export
 * 
 * @param {H.map.Polyline} polyline the route polyline
 * @param {H.map.Group} vertices the points on the route
 * @param {H.map.Group} stops the stops on the route
 * @returns {{geometry:{lat:number, lng:number}[], stops:{lat:number, lng:number}[], details:{}}}
 */
function prepareRouteForExport(polyline, vertices, stops) {
    let result = { geometry: [], stops: [], details: {} }

    vertices.forEach((v) => {
        result.geometry.push({ lat: v.getGeometry().lat, lng: v.getGeometry().lng });
    });

    if (stops !== null) {
        let includeStopProperties = ["stopName", "sequenceNo", "stopId"];
        stops.forEach((s) => {
            let stop = { lat: s.getGeometry().lat, lng: s.getGeometry().lng };
            for (const key in s.getData()) {
                if (includeStopProperties.includes(key)) {
                    stop[key] = s.getData()[key];
                }
            }
            result.stops.push(stop);
        });
    }

    return result;
}

/**
 * Converts from degrees to radians.
 * @param {number} degrees 
 * @returns {number} radians
 */
function toRadians(degrees) {
    return degrees * Math.PI / 180;
};

/**
 * Converts from radians to degrees.
 * @param {number} radians 
 * @returns {number} degrees
 */
function toDegrees(radians) {
    return radians * 180 / Math.PI;
}

/**
 * Calculate the bearing from one to location to another
 * @param {H.geo.Point} start 
 * @param {H.geo.Point} dest 
 * @returns {number} the bearing (degrees) from start to dest
 */
function calculateBearing(start, dest) {
    let startLat = toRadians(start.lat),
        startLng = toRadians(start.lng),
        destLat = toRadians(dest.lat),
        destLng = toRadians(dest.lng);

    let y = Math.sin(destLng - startLng) * Math.cos(destLat);
    let x = Math.cos(startLat) * Math.sin(destLat) -
        Math.sin(startLat) * Math.cos(destLat) * Math.cos(destLng - startLng);
    let brng = Math.atan2(y, x);
    brng = toDegrees(brng);
    return (brng + 360) % 360;
}

export {
    createRoutePolyline, createStopMarkers, zoomToObject, setDraggable, createCustomMapUiControl,
    initialisePreviousRouteValues, restorePreviousRouteValues, wasRouteModified, calculateRoute,
    createFilteredRoutePolyline, prepareRouteForExport, addRouteEventListeners, addMapEventListeners,
    deleteRouteStop, validateRoute, updateStopSequenceNumbers, createReferenceRoutePolyline,
    mapGroupNamePlannedRoute, mapGroupNameCalculatedRoute, maxRouteWaypoints
};
