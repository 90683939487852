/**
 * @module AppDefs
 * @author David Kirkland <david.kirkland@nec.com.au>
 * @copyright NEC Australia
 */

// Application version
const APP_VERSION = '1.13';

// Alert severity levels
class AlertSeverity{
    static ERROR = 'error';
    static WARNING = 'warning';
    static INFO = 'info';
    static SUCCESS = 'success';

    static isValid(severity){
        return severity !== null && Object.values(AlertSeverity).includes(severity);
    }

    static getLevels(){
        return Object.values(AlertSeverity);
    }
}

// Some simple user roles
const UserRole = Object.freeze({
    NEC_ADMIN: 'NECAdmin',
    CUSTOMER_ADMIN: 'CustomerAdmin'
});

export { AlertSeverity, UserRole, APP_VERSION };