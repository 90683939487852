/**
 * Route utility functions
 * @author David Kirkland <david.kirkland@nec.com.au>
 */

/**
 * Match a route name against a pattern for sorting purposes
 * @param {string} route the route name
 * @returns {i:string, s:string}
 */
function routePatternMatch(route) {
    // assume the route is a number followed by an optional suffix
    let match = route.match(/^([0-9]*)([A-Za-z]*)$/),
        intPart = null,
        stringPart = route;

    if (match !== null) {
        intPart = match[1] || null;
        stringPart = match[2] || '';
    }

    return { i: intPart, s: stringPart };
}

/**
 * custom sort function for sorting a string which is the form of a number then optional suffix
 * @param {string} a the first object to compare
 * @param {string} b the second object to compare
 * @returns {number} negative if a < b, positive if a > b, zero if a ==
 */
function sortByNumberThenSuffix(a, b) {
    let mA = routePatternMatch(a),
        mB = routePatternMatch(b),
        result = 0;

    // sort by number then suffix if it exists
    if (mA.i == null) {
        if (mB.i == null)
            result = mA.s.localeCompare(mB.s);
        else
            result = 1;
    } else if (mB.i == null)
        result = -1;
    else if (mA.i === mB.i)
        result = mA.s.localeCompare(mB.s);
    else
        result = mA.i - mB.i;
    return result;
}

/**
 * sort a route list by route name then destination then variant
 * @param {*} a the first route object
 * @param {*} b the second route object
 * @returns {number} negative if a < b, positive if a > b, zero if a == b
 */
function routeSorter(a, b) {
    let result = 0;

    // sort by route name
    if (a.hasOwnProperty('route') && b.hasOwnProperty('route'))
        result = sortByNumberThenSuffix(a.route, b.route);
    else if (a.hasOwnProperty('routeName') && b.hasOwnProperty('routeName'))
        result = sortByNumberThenSuffix(a.routeName, b.routeName);

    // if the route names are the same, sort by destination
    if (result === 0) {
        if (a.hasOwnProperty('destination') && b.hasOwnProperty('destination'))
            result = a.destination.localeCompare(b.destination);
        else if (a.hasOwnProperty('routeDestination') && b.hasOwnProperty('routeDestination'))
            result = a.routeDestination.localeCompare(b.routeDestination);
    }

    // if the route names and destinations are the same, sort by variant
    if (result === 0) {
        if (a.hasOwnProperty('variant') && b.hasOwnProperty('variant'))
            result = sortByNumberThenSuffix(a.variant, b.variant);
        else if (a.hasOwnProperty('routeVariant') && b.hasOwnProperty('routeVariant'))
            result = sortByNumberThenSuffix(a.routeVariant, b.routeVariant);
    }
    return result;
}

export {
    routeSorter
};
