/**
 * @module App
 * @author David Kirkland <david.kirkland@nec.com.au>
 * @copyright NEC Australia
 */
import React from 'react';
import Map from './Map';
import './App.css';
import { DenseAppBar as AppBar } from './AppBar';
import Alert from '@mui/material/Alert';
import Snackbar from '@mui/material/Snackbar';
import Backdrop from '@mui/material/Backdrop';
import { Amplify, Hub, Logger } from 'aws-amplify';
import awsExports from './aws-exports';
import { Authenticator, View, Image, useTheme, Loader } from '@aws-amplify/ui-react';
import { checkAuthenticatedUser, changeCurrentUserPassword, fetchAppConfiguration, fetchCustomerList, configureAwsStorage } from './AwsFunctions';
import ManageRoutes from './ManageRoutes';
import ImportRoutes from './ImportRoutes';
import nec_logo from './images/nec-logo-digital-necblue-transparentbg-mediumres.png'
import { viewEditRoute, viewManageRoute, viewImportRoute } from './AppBar';
import { AlertDialog, SwitchCustomerDialog, ChangePasswordDialog } from './DialogUtils';
import { APP_VERSION, AlertSeverity, UserRole } from './AppDefs';

const logger = new Logger('App', 'INFO');
const components = {
  Header() {
    const { tokens } = useTheme();

    return (
      <View textAlign="center" padding={tokens.space.large}>
        <Image
          alt="logo"
          //src="https://docs.amplify.aws/assets/logo-dark.svg"
          src={nec_logo}
        />
      </View>
    );
  },
};

const restrictedAccessAlertSuffix = 'restricted access applies.';

// The default customer name to use if one is not specified
const defaultCustomer = 'rrm-default';

Amplify.configure(awsExports);

// Main application class
export default class App extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      zoom: 0,
      lat: 0,
      lng: 0,
      routeDetails: null,
      routeEditEnabled: false,
      routeSaveEnabled: false,
      routeSource: null,
      alertMessage: null,
      snackbarMessage: null,
      menuAction: null,
      activeView: viewEditRoute,
      loggedInUserDetails: null,
      appConfiguration: null,
      showCircularProgressBar: false,
      showSwitchCustomerDialog: false,
      showChangePasswordDialog: false,
      activeCustomer: null,
      restrictedAccess: { 'active': false, 'message': null, 'logoutOnAck': false },
    }
    this.appBarHeight = 48; // pixels
    this.amplifySignOut = null;
    this.customerList = null;
    this.switchCustomerList = [];
    this.userRoles = [];
    this.appConfigReadAttempts = -1;
    this.handleSignOut = this.handleSignOut.bind(this);
    this.handleChangePassword = this.handleChangePassword.bind(this);
    this.handleAppConfigResponse = this.handleAppConfigResponse.bind(this);
    this.handleCustomerSelected = this.handleCustomerSelected.bind(this);
    this.checkLoggedInUser = this.checkLoggedInUser.bind(this);
  }

  componentDidMount() {
    if (!this.alreadyMounted) {
      this.alreadyMounted = true;
      // listen for AWS Amplify authentication events, then check the authentication status of the user
      this.hubListenerCancelToken = Hub.listen('auth', this.amplifyAuthListener);
      this.checkLoggedInUser(true);
    }
  }

  componentWillUnmount() {
    if (this.apiKeyReadTimerId) {
      clearTimeout(this.apiKeyReadTimerId);
      this.apiKeyReadTimerId = null;
    }
  }

  // load the list of customers from AWS
  loadCustomerList(loggedInUser) {
    // get the list of customers from AWS
    fetchCustomerList((customerList) => {
      if (customerList) {
        this.customerList = customerList;
        if (loggedInUser) {
          this.checkUserConfiguration(this.state.appConfiguration, loggedInUser);
        }
      }
    });
  }

  // find the specified customer in the customers list
  findCustomer(customer) {
    if (customer && this.customerList) {
      let filteredCustomerList = this.customerList.filter((val) => val.name === customer);
      if (filteredCustomerList.length > 0) {
        // use the first match (there should only be 1 anyway)
        return filteredCustomerList[0];
      }
    }
    return null;
  }

  // get the display name for the specified customer
  getCustomerDisplayName(customer) {
    if (customer && this.customerList) {
      let customerMatch = this.findCustomer(customer);
      if (customerMatch) {
        return customerMatch.displayName;
      }
    }
    return null;
  }

  // get the storage bucket for the specified customer
  getCustomerStorageBucket(customer) {
    if (customer && this.customerList) {
      let customerMatch = this.findCustomer(customer);
      if (customerMatch) {
        return customerMatch.storageBucket;
      }
    }
    return null;
  }

  // get the operator name for the specified customer
  getCustomerOperatorName(customer) {
    if (customer && this.customerList) {
      let customerMatch = this.findCustomer(customer);
      if (customerMatch) {
        return customerMatch.tmsOperatorGroupName;
      }
    }
    return null;
  }

  // handle the selection of a different customer
  handleCustomerSelected(customer) {
    this.setState({ showSwitchCustomerDialog: false });
    let customerDisplayName = this.getCustomerDisplayName(customer);
    if (customerDisplayName) {
      logger.info("Switch to customer: " + customer + " (" + customerDisplayName + ")");
      this.setActiveCustomer(customer, customerDisplayName, this.getCustomerStorageBucket(customer),
        this.getCustomerOperatorName(customer));
    }
  }

  // set the active customer
  setActiveCustomer(name, displayName, storageBucket, tmsOperatorGroupName) {
    logger.info("setActiveCustomer: " + name + " (" + displayName + ")");
    this.setState({ activeCustomer: { 'name': name, 'displayName': displayName, 'tmsOperatorGroupName': tmsOperatorGroupName } });
    configureAwsStorage(name, storageBucket);
    if (!name) {
      logger.info("Customer not configured - using default storage location");
    }
  }

  // Set restricted access to the app
  setRestrictedAccess(restrictedAccess) {
    if (restrictedAccess.active && !this.state.restrictedAccess.active) {
      this.setState({ restrictedAccess: restrictedAccess });
    }
  }

  // Check the user configuration against what the app is expecting
  checkUserConfiguration(appConfiguration, userDetails) {
    let user = userDetails;
    if (appConfiguration === null || this.customerList === null || user === null) {
      return;
    }

    user.storageBucket = null
    let customerDetails = this.findCustomer(user.customer);
    if (!customerDetails) {
      logger.warn("Configured customer (" + user.customer + ") not found in the customer list");
      if (user.customer || appConfiguration.usersRequireCustomer) {
        // raise an error if there is no match for the configured customer or the customer is not defined when we require it to be
        this.handleAlertRequest("Customer configuration error - contact support", AlertSeverity.ERROR, 0);
      } else if (!user.customer) {
        customerDetails = this.findCustomer(defaultCustomer);
        if (customerDetails) {
          user.storageBucket = customerDetails.storageBucket;
          user.customerDisplayName = customerDetails.displayName;
        }
      }
    } else if (!user.customerDisplayName) {
      user.customerDisplayName = this.getCustomerDisplayName(user.customer);
      user.storageBucket = this.getCustomerStorageBucket(user.customer);
      this.setState({ loggedInUserDetails: user });
    }

    // Create a list of customers that the user can switch between (based on their role)
    this.switchCustomerList = [];
    if (this.customerList !== null) {
      if (this.userRoles.includes(UserRole.NEC_ADMIN)) {
        // NEC admin users can switch to any customer
        this.switchCustomerList = this.customerList;
      } else if (this.userRoles.includes(UserRole.CUSTOMER_ADMIN) && customerDetails !== null && customerDetails.hasOwnProperty('childCustomers')) {
        // Customer admin users can only switch to their own child customers (and themselves)
        this.switchCustomerList = this.customerList.filter((val) => (customerDetails.childCustomers.includes(val.name) || customerDetails.name === val.name));
      }
    }
    logger.debug("Switch customer list: ", this.switchCustomerList);


    if (user.storageBucket) {
      logger.info("Using storage bucket from configuration: " + user.storageBucket);
    }
    if (!this.state.activeCustomer) {
      this.setActiveCustomer(user.customer, user.customerDisplayName, user.storageBucket);
    }

    // Depending on configuration, the user may be required to belong to a specific group in order to access the full functions of the app
    if (appConfiguration.appGroupName && appConfiguration.appGroupName.toLowerCase() !== 'none' && (!user.groups?.includes(appConfiguration.appGroupName))) {
      logger.warn("User does not belong to the " + appConfiguration.appGroupName + " group");
      this.setRestrictedAccess({
        'active': true,
        'message': 'User is not authorised - ' + restrictedAccessAlertSuffix + '\nContact the administrator or use the Console to assign the appropriate role.',
        'logoutOnAck': false
      });
    }
  }

  // load the app configuration
  loadAppConfiguration() {
    if (this.appConfigReadAttempts <= 0) {
      this.appConfigReadAttempts = 3;
      if (this.appConfigReadTimerId) {
        clearTimeout(this.appConfigReadTimerId);
        this.appConfigReadTimerId = null;
      }
      fetchAppConfiguration(this.handleAppConfigResponse);
    }
  }

  // handle the app configuration response 
  handleAppConfigResponse(response) {
    if (typeof response === 'object' && response !== null) {
      if (!response.mapApiKey) {
        logger.warn("Map API key is empty");
        this.handleAlertRequest("App configuration error", AlertSeverity.ERROR, 0);
      }
      this.setState({ appConfiguration: response });
      this.appConfigReadAttempts = -1;
      this.checkUserConfiguration(response, this.state.loggedInUserDetails);
    } else if (this.appConfigReadAttempts > 0) {
      // Failed to load the app config - retry after a short delay
      this.appConfigReadAttempts--;
      this.appConfigReadTimerId = setTimeout(() => {
        fetchAppConfiguration(this.handleAppConfigResponse);
      }, 1000);
    } else {
      this.handleAlertRequest("Unable to load app configuration", AlertSeverity.ERROR, 0);
    }
  }

  // handle a change to the map view
  handleMapViewChange = (zoom, lat, lng) => {
    this.setState({
      lat,
      lng,
      zoom
    })
  }

  // handle a change to the route details
  handleRouteChange = (details) => {
    let routeDetails = null;
    if (details) {
      routeDetails = details.route;
      if (details.destination) {
        routeDetails += " - " + details.destination;
        if (details.variant) {
          routeDetails += " (" + details.variant + ")";
        }
      }
    }
    this.setState({
      routeDetails: routeDetails
    })
  }

  // handle route edit mode change
  handleRouteEditModeChange = (saveAllowed, editing, routeSource) => {
    this.setState({
      routeSaveEnabled: saveAllowed,
      routeEditEnabled: editing,
      routeSource: routeSource
    })
  }

  // handle a request to display an alert to the user
  handleAlertRequest = (alertMessage, severity, timeoutMsec, useSnackbar) => {
    let severityLevels = AlertSeverity.getLevels();
    let severityLevel = AlertSeverity.INFO;
    if (AlertSeverity.isValid(severity)) {
      severityLevel = severity;
    }

    if (useSnackbar) {
      this.setState({ snackbarMessage: alertMessage });
      this.snackbarSeverity = severityLevel;
      this.snackbarAutoHideDuration = timeoutMsec === 0 ? null : timeoutMsec;
    } else if (!alertMessage || !this.state.alertMessage || (severityLevels.indexOf(severityLevel) <= severityLevels.indexOf(this.alertSeverity))) {
      this.setState({ alertMessage: alertMessage });
      this.alertSeverity = severityLevel;

      if (this.alertTimerId) {
        clearTimeout(this.alertTimerId);
        this.alertTimerId = null;
      }
      let alertTimeoutMsec = timeoutMsec;
      if (alertTimeoutMsec == null) {
        alertTimeoutMsec = 3000;
      }
      if (alertTimeoutMsec > 0) {
        this.alertTimerId = setTimeout(() => {
          // Clear the alert after the specified timeout
          this.setState({ alertMessage: null })
        }, alertTimeoutMsec);
      }
    }
  }

  // format an optional snackbar message to be displayed at the bottom of the screen
  formatSnackbar() {
    const handleClose = () => {
      this.setState({ snackbarMessage: null });
    }
    return (
      <Snackbar
        open={Boolean(this.state.snackbarMessage)}
        anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
        autoHideDuration={this.snackbarAutoHideDuration}
        onClose={handleClose}>
        <Alert variant="filled" severity={this.snackbarSeverity} onClose={handleClose}>
          {this.state.snackbarMessage}
        </Alert>
      </Snackbar>);
  }

  // format an optional alert message to be displayed at the top of the screen
  formatAlert() {
    if (this.state.alertMessage) {
      return (
        <Alert
          variant="filled"
          severity={this.alertSeverity}
          onClose={() => {
            this.setState({ alertMessage: null });
            clearTimeout(this.alertTimerId);
          }}
        >{this.state.alertMessage}
        </Alert>);
    } else {
      return null;
    }
  }

  // format the app bar displayed at the top of the screen
  formatAppBar(signOut, mapIsVisible) {
    const {
      routeDetails,
      routeEditEnabled,
      routeSaveEnabled,
      routeSource,
      restrictedAccess,
      activeView,
      loggedInUserDetails,
      appConfiguration,
      activeCustomer
    } = this.state;

    let customerSwitchingAllowed = (this.userRoles.includes(UserRole.NEC_ADMIN) || this.userRoles.includes(UserRole.CUSTOMER_ADMIN));

    this.amplifySignOut = signOut;

    return (
      <AppBar
        title="NEC Route Review Manager"
        activeView={activeView}
        mapVisible={mapIsVisible}
        routeDetails={routeDetails}
        routeEditEnabled={routeEditEnabled}
        routeSaveEnabled={routeSaveEnabled}
        routeSource={routeSource}
        restrictedAccess={restrictedAccess.active}
        height={this.appBarHeight}
        aboutContent={'Version ' + APP_VERSION}
        onSignOut={this.handleSignOut}
        onMenuAction={(action) => { this.setState({ menuAction: action }) }}
        onLoadView={(view) => {
          if (view === viewEditRoute && (appConfiguration === null || appConfiguration.mapApiKey === null)) {
            // request to load the map view but the API key is not loaded yet
            this.loadAppConfiguration();
          }
          this.setState({ activeView: view, routeDetails: null, showCircularProgressBar: false })
        }}
        loggedInUser={loggedInUserDetails}
        showActiveCustomer={customerSwitchingAllowed}
        customerLabel={appConfiguration === null ? null : appConfiguration.customerLabel}
        activeCustomerDisplayName={activeCustomer && activeCustomer.displayName}
        onSwitchCustomer={() => {
          if (customerSwitchingAllowed)
            this.setState({ showSwitchCustomerDialog: true });
        }}
        onChangePassword={() => { this.setState({ showChangePasswordDialog: true }); }}
        onCheckLoggedInUser={this.checkLoggedInUser}
      />);
  }

  // handle sign out using AWS amplify
  handleSignOut() {
    if (this.amplifySignOut) {
      this.amplifySignOut();
      this.handleRouteChange(null, null, null);
      this.setState({ alertMessage: null, snackbarMessage: null });
    }
  }

  // handle password change request using AWS amplify
  handleChangePassword(oldPassword, newPassword) {
    changeCurrentUserPassword(oldPassword, newPassword, (error) => {
      if (!error) {
        this.setState({ showChangePasswordDialog: false });
        this.handleAlertRequest("Password changed successfully", AlertSeverity.SUCCESS, 5000, true);
      } else {
        this.handleAlertRequest("Error: " + error, AlertSeverity.ERROR, 5000, true);
      }
    });
  }

  // check the logged in user details
  checkLoggedInUser(force = false, alertIfNotVerified = false) {
    if (!this.checkUserTimerId || force) {
      checkAuthenticatedUser((user) => {
        let alertMessage2 = 'Sign-in again to verify user details.',
          restrictedAccess = { 'active': false, 'message': null, 'logoutOnAck': true };
        if (user) {
          user.customerDisplayName = this.getCustomerDisplayName(user.customer);
          this.userRoles = [];
          if (user.groups) {
            if (user.groups.includes('NECDevelopment') || user.groups.includes('NECSupport')) {
              // NEC support/dev staff
              this.userRoles.push(UserRole.NEC_ADMIN);
            }
            if (user.groups.includes('CustomerAdmin')) {
              // Customer admins
              this.userRoles.push(UserRole.CUSTOMER_ADMIN);
            }
          } else {
            logger.info("No groups configured for this user");
          }

          if (!user.isVerified) {
            restrictedAccess.active = true;
            restrictedAccess.message = 'User email is not verified - ';
          } else {
            logger.info("User email has been verified");
          }

          // Now that the user is confirmed to be authenticated, load the map API key and the customer list if we haven't already done so
          if (!this.state.appConfiguration) {
            this.loadAppConfiguration();
          } else {
            // check the user configuration
            this.checkUserConfiguration(this.state.appConfiguration, user);
          }
          if (!this.customerList) {
            this.loadCustomerList(user);
          }
        } else {
          restrictedAccess.active = true;
          restrictedAccess.message = 'User details are unavailable - ';
        }

        if (restrictedAccess.message) {
          restrictedAccess.message += restrictedAccessAlertSuffix + '\n' + alertMessage2;
        } else if (this.state.alertMessage?.includes(restrictedAccessAlertSuffix)) {
          this.setState({ alertMessage: null, snackbarMessage: null });
        }

        this.setState({ loggedInUserDetails: user });
        this.setRestrictedAccess(restrictedAccess);
      });

      // prevent further checks for a short period of time (avoid user check loop)
      if (this.checkUserTimerId) {
        clearTimeout(this.checkUserTimerId);
      }
      this.checkUserTimerId = setTimeout(() => {
        this.checkUserTimerId = null;
      }, 5000);
    }
  }

  // listen for amplify authentication events
  amplifyAuthListener = (data) => {
    switch (data.payload.event) {
      case 'signIn':
        logger.info('user signed in');
        this.setState({ alertMessage: null, snackbarMessage: null, restrictedAccess: { 'active': false, 'message': null } });
        this.checkLoggedInUser(true);
        break;
      case 'tokenRefresh':
        this.checkLoggedInUser(false);
        break;
      case 'signOut':
        logger.info('user signed out');
        this.setState({ alertMessage: null, snackbarMessage: null, restrictedAccess: { 'active': false, 'message': null } });
        break;
      default:
        logger.info(data.payload.event);
        break;
    }
  };

  render() {
    const {
      zoom,
      lat,
      lng,
      menuAction,
      activeView,
      loggedInUserDetails,
      appConfiguration,
      showCircularProgressBar,
      showSwitchCustomerDialog,
      showChangePasswordDialog,
      restrictedAccess,
      activeCustomer,
    } = this.state;

    let mapIsVisible = (activeView === viewEditRoute && appConfiguration !== null && appConfiguration.mapApiKey !== null);

    return (
      <Authenticator components={components} hideSignUp>
        {({ signOut, user }) => (
          <div className="App">
            {this.formatAlert() || this.formatAppBar(signOut, mapIsVisible)}

            {mapIsVisible &&
              <Map
                lat={lat}
                lng={lng}
                onMapViewChange={this.handleMapViewChange}
                onRouteChange={this.handleRouteChange}
                onRouteEditModeChange={this.handleRouteEditModeChange}
                onAlert={this.handleAlertRequest}
                zoom={zoom}
                excludeHeight={this.appBarHeight}
                menuAction={menuAction}
                onMenuActionAck={() => { this.setState({ menuAction: null }) }}
                loggedInUser={loggedInUserDetails}
                activeCustomer={activeCustomer}
                apiKey={appConfiguration === null ? null : appConfiguration.mapApiKey}
                mapDefaultLocation={appConfiguration === null ? null : appConfiguration.mapDefaultLocation}
                showCircularProgressBar={(show) => { this.setState({ showCircularProgressBar: show }) }}
              />
            }

            {activeView === viewManageRoute &&
              <ManageRoutes
                activeCustomer={activeCustomer}
                excludeHeight={this.appBarHeight}
                onAlert={this.handleAlertRequest}
                showCircularProgressBar={(show) => { this.setState({ showCircularProgressBar: show }) }}
              />
            }

            {activeView === viewImportRoute &&
              <ImportRoutes
                activeCustomer={activeCustomer}
                isAdminUser={this.userRoles.includes(UserRole.NEC_ADMIN)}
                excludeHeight={this.appBarHeight}
                onAlert={this.handleAlertRequest}
                showCircularProgressBar={(show) => { this.setState({ showCircularProgressBar: show }) }}
              />
            }

            <Backdrop
              sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
              open={showCircularProgressBar || (activeView === viewEditRoute && (appConfiguration === null || appConfiguration.mapApiKey === null) && this.apiKeyReadAttempts > 0)}
            >
              <Loader className="amplify-loader" isDeterminate={false} />
            </Backdrop>

            <SwitchCustomerDialog
              show={showSwitchCustomerDialog}
              customerLabel={appConfiguration === null ? null : appConfiguration.customerLabel}
              customerList={this.switchCustomerList}
              onOk={this.handleCustomerSelected}
              onCancel={() => { this.setState({ showSwitchCustomerDialog: false }) }}
            />

            <ChangePasswordDialog
              show={showChangePasswordDialog}
              onOk={this.handleChangePassword}
              onCancel={() => { this.setState({ showChangePasswordDialog: false }) }}
            />

            <AlertDialog
              title='Warning'
              content={restrictedAccess.message}
              onOk={() => {
                if (restrictedAccess.logoutOnAck) {
                  this.handleSignOut()
                } else {
                  this.setState({ restrictedAccess: { 'active': true, 'message': null } });
                  this.handleAlertRequest('Access is restricted', AlertSeverity.WARNING, 3000, true);
                }
              }}
              onCancel={() => {
                this.setState({ restrictedAccess: { 'active': true, 'message': null } });
                this.handleAlertRequest('Access is restricted', AlertSeverity.WARNING, 3000, true);
              }}
            />
            {this.formatSnackbar()}
          </div>
        )}
      </Authenticator>

    );
  }
}
