import React, { useState, useEffect } from 'react';
import AppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
import IconButton from '@mui/material/IconButton';
import MenuIcon from '@mui/icons-material/Menu';
import AccountCircleIcon from '@mui/icons-material/AccountCircleOutlined';
import FileUploadIcon from '@mui/icons-material/FileUploadOutlined';
import FileDownloadIcon from '@mui/icons-material/FileDownloadOutlined';
import FileOpenIcon from '@mui/icons-material/FileOpenOutlined';
import FileSaveIcon from '@mui/icons-material/SaveOutlined';
import FileSaveAsIcon from '@mui/icons-material/SaveAsOutlined';
import FileNewIcon from '@mui/icons-material/NoteAddOutlined';
import LogOutIcon from '@mui/icons-material/Logout';
import EditIcon from '@mui/icons-material/Edit';
import CategoryIcon from '@mui/icons-material/Category';
import ImportIcon from '@mui/icons-material/Upload';
import InfoIcon from '@mui/icons-material/Info';
import ManageAccountsIcon from '@mui/icons-material/ManageAccountsOutlined';
import LockOutlinedIcon from '@mui/icons-material/LockOutlined';
import Drawer from '@mui/material/Drawer';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemText from '@mui/material/ListItemText';
import ListItemIcon from '@mui/material/ListItemIcon';
import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import MenuItem from '@mui/material/MenuItem';
import Menu from '@mui/material/Menu';
import Tooltip from '@mui/material/Tooltip';
import Divider from '@mui/material/Divider';
import Stack from '@mui/material/Stack';
import { Badge } from '@aws-amplify/ui-react';
import {
  storageFolderDraft, storageFolderReview, storageFolderApproved
} from './AwsFunctions';
import PropTypes from 'prop-types';

const menuActionSaveRoute = "save route",
  menuActionSaveAsRoute = "save as route",
  menuActionOpenRoute = "open route",
  menuActionUploadRoute = "upload route",
  menuActionDownloadRoute = "download route",
  menuActionNewRoute = "new route";

const viewEditRoute = "edit",
  viewManageRoute = "manage",
  viewImportRoute = "import";

/**
 * Create the App Bar to display information and actions for the app
 * @param {{}} props 
 * @returns AppBar component to be rendered
 */
function DenseAppBar(props) {
  const [isDrawerOpen, setIsDrawerOpen] = useState(false);
  const [showAboutDialog, setShowAboutDialog] = useState(false);
  const [userMenuAnchorEl, setUserMenuAnchorEl] = useState(null);
  let { restrictedAccess, onCheckLoggedInUser } = props;

  // run this Effect on component mount, or if the restricted access setting changes
  useEffect(() => {
    // if restricted access applies, re-check the user details
    if (restrictedAccess) {
      onCheckLoggedInUser(true, true);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [restrictedAccess]);

  const handleUserMenu = (event) => {
    setUserMenuAnchorEl(event.currentTarget);
  };

  const handleUserMenuClose = () => {
    setUserMenuAnchorEl(null);
  };

  let isRouteLoaded = Boolean(props.routeDetails);

  return (
    <Box sx={{ flexGrow: 1 }}>
      <AppBar position="static" sx={{ bgcolor: "darkblue", height: (props.height || 48) }}>
        <Toolbar variant="dense">
          <IconButton
            edge="start"
            color="inherit"
            aria-label="menu"
            sx={{ mr: 2 }}
            onClick={() => setIsDrawerOpen(true)}
          >
            <MenuIcon />
          </IconButton>
          <Typography variant="h6" color="inherit" component="div" noWrap>
            {props.title}
          </Typography>
          <Typography variant="h6" color="lightgrey" component="div" noWrap style={{ marginLeft: 0, marginRight: (props.activeView === viewEditRoute ? 0 : 260), flexGrow: 1, textAlign: "center" }}>
            {props.activeView === viewManageRoute ? 'Manage Routes' : props.activeView === viewImportRoute ? 'Import Routes' : props.routeDetails}
          </Typography>
          {(props.activeView === viewEditRoute && props.routeDetails && storageFolderDraft.startsWith(props.routeSource)) && <Badge size="small" variation="info">Draft</Badge>}
          {(props.activeView === viewEditRoute && props.routeDetails && storageFolderReview.startsWith(props.routeSource)) && <Badge size="small" variation="warning">Review</Badge>}
          {(props.activeView === viewEditRoute && props.routeDetails && storageFolderApproved.startsWith(props.routeSource)) && <Badge size="small" variation="success">Approved</Badge>}

          {props.mapVisible &&
            <Stack direction="row">
              <Tooltip title="Create new route">
                <span>
                  <IconButton
                    size="medium"
                    aria-label={menuActionNewRoute}
                    onClick={() => { props.onMenuAction(menuActionNewRoute) }}
                    color="inherit"
                    disabled={true || (isRouteLoaded && props.routeEditEnabled)}
                  >
                    <FileNewIcon />
                  </IconButton>
                </span>
              </Tooltip>

              <Tooltip title="Open route">
                <span>
                  <IconButton
                    size="medium"
                    aria-label={menuActionOpenRoute}
                    onClick={() => { props.onMenuAction(menuActionOpenRoute) }}
                    color="inherit"
                    disabled={(isRouteLoaded && props.routeEditEnabled) || props.restrictedAccess}
                  >
                    <FileOpenIcon />
                  </IconButton>
                </span>
              </Tooltip>

              <Tooltip title="Save route">
                <span>
                  <IconButton
                    size="medium"
                    aria-label={menuActionSaveRoute}
                    onClick={() => { props.onMenuAction(menuActionSaveRoute) }}
                    color="inherit"
                    disabled={!isRouteLoaded || !props.routeSaveEnabled || props.routeEditEnabled || props.restrictedAccess}
                  >
                    <FileSaveIcon />
                  </IconButton>
                </span>
              </Tooltip>

              <Tooltip title="Save as new route">
                <span>
                  <IconButton
                    size="medium"
                    aria-label={menuActionSaveAsRoute}
                    onClick={() => { props.onMenuAction(menuActionSaveAsRoute) }}
                    color="inherit"
                    disabled={!isRouteLoaded || props.routeEditEnabled || props.restrictedAccess}
                  >
                    <FileSaveAsIcon />
                  </IconButton>
                </span>
              </Tooltip>

              <Tooltip title="Upload route from local computer">
                <span>
                  <IconButton
                    size="medium"
                    aria-label={menuActionUploadRoute}
                    onClick={() => { props.onMenuAction(menuActionUploadRoute) }}
                    color="inherit"
                    disabled={isRouteLoaded && props.routeEditEnabled}
                  >
                    <FileUploadIcon />
                  </IconButton>
                </span>
              </Tooltip>

              <Tooltip title="Download route to local computer">
                <span>
                  <IconButton
                    size="medium"
                    aria-label={menuActionDownloadRoute}
                    onClick={() => { props.onMenuAction(menuActionDownloadRoute) }}
                    color="inherit"
                    disabled={!isRouteLoaded || props.routeEditEnabled}
                  >
                    <FileDownloadIcon />
                  </IconButton>
                </span>
              </Tooltip>
            </Stack>
          }


          <Tooltip title="User settings">
            <IconButton
              size="medium"
              aria-label="account of current user"
              aria-controls="user-menu-appbar"
              aria-haspopup="true"
              onClick={handleUserMenu}
              color="inherit"
            >
              <AccountCircleIcon />
            </IconButton>
          </Tooltip>

          <Menu
            id="user-menu-appbar"
            anchorEl={userMenuAnchorEl}
            anchorOrigin={{
              vertical: 'top',
              horizontal: 'right',
            }}
            keepMounted
            transformOrigin={{
              vertical: 'top',
              horizontal: 'right',
            }}
            open={Boolean(userMenuAnchorEl)}
            onClose={handleUserMenuClose}
          >
            <MenuItem disabled style={{ opacity: 1, fontWeight: "bold" }}>Account</MenuItem>
            <MenuItem onClick={handleUserMenuClose}>
              <ListItemIcon>
                <AccountCircleIcon />
              </ListItemIcon>
              <ListItemText
                primary={props.loggedInUser ? props.loggedInUser.name : null}
                secondaryTypographyProps={{ component: 'div' }}
                secondary={
                  <div>
                    <div>{props.loggedInUser ? props.loggedInUser.email : null}</div>
                    <div>{props.loggedInUser && (props.loggedInUser.customerDisplayName ? props.loggedInUser.customerDisplayName :
                      props.loggedInUser.customer ? props.loggedInUser.customer.toUpperCase() : null)}</div>
                  </div>
                }
              />
            </MenuItem>
            {props.showActiveCustomer &&
              <MenuItem onClick={() => { handleUserMenuClose(); props.onSwitchCustomer(); }}>
                <ListItemIcon>
                  <ManageAccountsIcon />
                </ListItemIcon>
                <ListItemText
                  primary={"Active " + (props.customerLabel || "Customer")}
                  secondary={props.activeCustomerDisplayName || "N/A"}
                />
              </MenuItem>
            }
            <Divider />
            <MenuItem onClick={() => { handleUserMenuClose(); props.onChangePassword(); }}>
              <ListItemIcon>
                <LockOutlinedIcon />
              </ListItemIcon>
              Change Password
            </MenuItem>
            <MenuItem onClick={() => { props.onSignOut() }}>
              <ListItemIcon>
                <LogOutIcon />
              </ListItemIcon>
              Sign Out
            </MenuItem>
          </Menu>

          <Drawer
            anchor="left"
            open={isDrawerOpen}
            onClose={() => setIsDrawerOpen(false)}
          >
            <List sx={{ width: 150 }}>
              <ListItem disablePadding>
                <ListItemButton
                  selected={props.activeView === viewEditRoute}
                  onClick={(event) => { setIsDrawerOpen(false); props.onLoadView(viewEditRoute); }}
                >
                  <ListItemIcon sx={{ color: props.activeView === viewEditRoute ? 'royalblue' : 'default' }}>
                    <EditIcon />
                  </ListItemIcon>
                  <ListItemText primary="Edit" />
                </ListItemButton>
              </ListItem>

              <ListItem disablePadding>
                <ListItemButton
                  disabled={props.restrictedAccess}
                  selected={props.activeView === viewManageRoute}
                  onClick={(event) => { setIsDrawerOpen(false); props.onLoadView(viewManageRoute); }}
                >
                  <ListItemIcon sx={{ color: props.activeView === viewManageRoute ? 'royalblue' : 'default' }}>
                    <CategoryIcon />
                  </ListItemIcon>
                  <ListItemText primary="Manage" />
                </ListItemButton>
              </ListItem>

              <ListItem disablePadding>
                <ListItemButton
                  disabled={props.restrictedAccess}
                  selected={props.activeView === viewImportRoute}
                  onClick={(event) => { setIsDrawerOpen(false); props.onLoadView(viewImportRoute); }}
                >
                  <ListItemIcon sx={{ color: props.activeView === viewImportRoute ? 'royalblue' : 'default' }}>
                    <ImportIcon />
                  </ListItemIcon>
                  <ListItemText primary="Import" />
                </ListItemButton>
              </ListItem>

              <ListItem disablePadding>
                <ListItemButton
                  onClick={(event) => { setIsDrawerOpen(false); setShowAboutDialog(true); }}
                >
                  <ListItemIcon>
                    <InfoIcon />
                  </ListItemIcon>
                  <ListItemText primary="About" />
                </ListItemButton>
              </ListItem>
            </List>
          </Drawer>
        </Toolbar>
      </AppBar>

      <Dialog
        open={showAboutDialog}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        onClose={() => setShowAboutDialog(false)}
      >
        <DialogTitle id="alert-dialog-title">
          {props.title}
        </DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            {props.aboutContent || "N/A"}
          </DialogContentText>
        </DialogContent>
      </Dialog>
    </Box>
  );
}

DenseAppBar.propTypes = {
  title: PropTypes.string.isRequired,
  activeView: PropTypes.string.isRequired,
  mapVisible: PropTypes.bool,
  routeDetails: PropTypes.string,
  routeEditEnabled: PropTypes.bool,
  routeSaveEnabled: PropTypes.bool,
  routeSource: PropTypes.string,
  restrictedAccess: PropTypes.bool,
  height: PropTypes.number,
  aboutContent: PropTypes.string.isRequired,
  onSignOut: PropTypes.func.isRequired,
  onMenuAction: PropTypes.func.isRequired,
  onLoadView: PropTypes.func.isRequired,
  loggedInUser: PropTypes.object,
  showActiveCustomer: PropTypes.bool,
  activeCustomerDisplayName: PropTypes.string,
  onSwitchCustomer: PropTypes.func.isRequired,
  onChangePassword: PropTypes.func.isRequired,
  onCheckLoggedInUser: PropTypes.func.isRequired
};

export {
  DenseAppBar, menuActionSaveRoute, menuActionSaveAsRoute, menuActionOpenRoute,
  menuActionUploadRoute, menuActionDownloadRoute, menuActionNewRoute,
  viewEditRoute, viewManageRoute, viewImportRoute
};